/*! elementor-icons - v5.15.0 - 30-01-2022 */
@font-face {
    font-display: swap;
    font-family: eicons;
    src: url('../font/eicons/fonts/eicons5519.eot');
    src: url('../font/eicons/fonts/eicons5519.eot') format("embedded-opentype"), url('../font/eicons/fonts/eicons5519.woff2') format("woff2"), url('../font/eicons/fonts/eicons5519.woff') format("woff"), url('../font/eicons/fonts/eicons5519.ttf') format("truetype"), url('../font/eicons/fonts/eicons5519.svg') format("svg");
    font-weight: 400;
    font-style: normal
}


[class*=" eicon-"],
[class^=eicon] {
    display: inline-block;
    font-family: eicons;
    font-size: inherit;
    font-weight: 400;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

@keyframes a {
    0% {
        transform: rotate(0deg)
    }

    to {
        transform: rotate(359deg)
    }
}

.eicon-animation-spin {
    animation: a 2s infinite linear
}

.eicon-editor-link:before {
    content: '\e800'
}

.eicon-editor-unlink:before {
    content: '\e801'
}

.eicon-editor-external-link:before {
    content: '\e802'
}

.eicon-editor-close:before {
    content: '\e803'
}

.eicon-editor-list-ol:before {
    content: '\e804'
}

.eicon-editor-list-ul:before {
    content: '\e805'
}

.eicon-editor-bold:before {
    content: '\e806'
}

.eicon-editor-italic:before {
    content: '\e807'
}

.eicon-editor-underline:before {
    content: '\e808'
}

.eicon-editor-paragraph:before {
    content: '\e809'
}

.eicon-editor-h1:before {
    content: '\e80a'
}

.eicon-editor-h2:before {
    content: '\e80b'
}

.eicon-editor-h3:before {
    content: '\e80c'
}

.eicon-editor-h4:before {
    content: '\e80d'
}

.eicon-editor-h5:before {
    content: '\e80e'
}

.eicon-editor-h6:before {
    content: '\e80f'
}

.eicon-editor-quote:before {
    content: '\e810'
}

.eicon-editor-code:before {
    content: '\e811'
}

.eicon-elementor:before {
    content: '\e812'
}

.eicon-elementor-circle:before {
    content: '\e813'
}

.eicon-pojome:before {
    content: '\e814'
}

.eicon-plus:before {
    content: '\e815'
}

.eicon-menu-bar:before {
    content: '\e816'
}

.eicon-apps:before {
    content: '\e817'
}

.eicon-accordion:before {
    content: '\e818'
}

.eicon-alert:before {
    content: '\e819'
}

.eicon-animation-text:before {
    content: '\e81a'
}

.eicon-animation:before {
    content: '\e81b'
}

.eicon-banner:before {
    content: '\e81c'
}

.eicon-blockquote:before {
    content: '\e81d'
}

.eicon-button:before {
    content: '\e81e'
}

.eicon-call-to-action:before {
    content: '\e81f'
}

.eicon-captcha:before {
    content: '\e820'
}

.eicon-carousel:before {
    content: '\e821'
}

.eicon-checkbox:before {
    content: '\e822'
}

.eicon-columns:before {
    content: '\e823'
}

.eicon-countdown:before {
    content: '\e824'
}

.eicon-counter:before {
    content: '\e825'
}

.eicon-date:before {
    content: '\e826'
}

.eicon-divider-shape:before {
    content: '\e827'
}

.eicon-divider:before {
    content: '\e828'
}

.eicon-download-button:before {
    content: '\e829'
}

.eicon-dual-button:before {
    content: '\e82a'
}

.eicon-email-field:before {
    content: '\e82b'
}

.eicon-facebook-comments:before {
    content: '\e82c'
}

.eicon-facebook-like-box:before {
    content: '\e82d'
}

.eicon-form-horizontal:before {
    content: '\e82e'
}

.eicon-form-vertical:before {
    content: '\e82f'
}

.eicon-gallery-grid:before {
    content: '\e830'
}

.eicon-gallery-group:before {
    content: '\e831'
}

.eicon-gallery-justified:before {
    content: '\e832'
}

.eicon-gallery-masonry:before {
    content: '\e833'
}

.eicon-icon-box:before {
    content: '\e834'
}

.eicon-image-before-after:before {
    content: '\e835'
}

.eicon-image-box:before {
    content: '\e836'
}

.eicon-image-hotspot:before {
    content: '\e837'
}

.eicon-image-rollover:before {
    content: '\e838'
}

.eicon-info-box:before {
    content: '\e839'
}

.eicon-inner-section:before {
    content: '\e83a'
}

.eicon-mailchimp:before {
    content: '\e83b'
}

.eicon-menu-card:before {
    content: '\e83c'
}

.eicon-navigation-horizontal:before {
    content: '\e83d'
}

.eicon-nav-menu:before {
    content: '\e83e'
}

.eicon-navigation-vertical:before {
    content: '\e83f'
}

.eicon-number-field:before {
    content: '\e840'
}

.eicon-parallax:before {
    content: '\e841'
}

.eicon-php7:before {
    content: '\e842'
}

.eicon-post-list:before {
    content: '\e843'
}

.eicon-post-slider:before {
    content: '\e844'
}

.eicon-post:before {
    content: '\e845'
}

.eicon-posts-carousel:before {
    content: '\e846'
}

.eicon-posts-grid:before {
    content: '\e847'
}

.eicon-posts-group:before {
    content: '\e848'
}

.eicon-posts-justified:before {
    content: '\e849'
}

.eicon-posts-masonry:before {
    content: '\e84a'
}

.eicon-posts-ticker:before {
    content: '\e84b'
}

.eicon-price-list:before {
    content: '\e84c'
}

.eicon-price-table:before {
    content: '\e84d'
}

.eicon-radio:before {
    content: '\e84e'
}

.eicon-rtl:before {
    content: '\e84f'
}

.eicon-scroll:before {
    content: '\e850'
}

.eicon-search:before {
    content: '\e851'
}

.eicon-select:before {
    content: '\e852'
}

.eicon-share:before {
    content: '\e853'
}

.eicon-sidebar:before {
    content: '\e854'
}

.eicon-skill-bar:before {
    content: '\e855'
}

.eicon-slider-3d:before {
    content: '\e856'
}

.eicon-slider-album:before {
    content: '\e857'
}

.eicon-slider-device:before {
    content: '\e858'
}

.eicon-slider-full-screen:before {
    content: '\e859'
}

.eicon-slider-push:before {
    content: '\e85a'
}

.eicon-slider-vertical:before {
    content: '\e85b'
}

.eicon-slider-video:before {
    content: '\e85c'
}

.eicon-slides:before {
    content: '\e85d'
}

.eicon-social-icons:before {
    content: '\e85e'
}

.eicon-spacer:before {
    content: '\e85f'
}

.eicon-table:before {
    content: '\e860'
}

.eicon-tabs:before {
    content: '\e861'
}

.eicon-tel-field:before {
    content: '\e862'
}

.eicon-text-area:before {
    content: '\e863'
}

.eicon-text-field:before {
    content: '\e864'
}

.eicon-thumbnails-down:before {
    content: '\e865'
}

.eicon-thumbnails-half:before {
    content: '\e866'
}

.eicon-thumbnails-right:before {
    content: '\e867'
}

.eicon-time-line:before {
    content: '\e868'
}

.eicon-toggle:before {
    content: '\e869'
}

.eicon-url:before {
    content: '\e86a'
}

.eicon-t-letter:before {
    content: '\e86b'
}

.eicon-wordpress:before {
    content: '\e86c'
}

.eicon-text:before {
    content: '\e86d'
}

.eicon-anchor:before {
    content: '\e86e'
}

.eicon-bullet-list:before {
    content: '\e86f'
}

.eicon-code:before {
    content: '\e870'
}

.eicon-favorite:before {
    content: '\e871'
}

.eicon-google-maps:before {
    content: '\e872'
}

.eicon-image:before {
    content: '\e873'
}

.eicon-photo-library:before {
    content: '\e874'
}

.eicon-woocommerce:before {
    content: '\e875'
}

.eicon-youtube:before {
    content: '\e876'
}

.eicon-flip-box:before {
    content: '\e877'
}

.eicon-settings:before {
    content: '\e878'
}

.eicon-headphones:before {
    content: '\e879'
}

.eicon-testimonial:before {
    content: '\e87a'
}

.eicon-counter-circle:before {
    content: '\e87b'
}

.eicon-person:before {
    content: '\e87c'
}

.eicon-chevron-right:before {
    content: '\e87d'
}

.eicon-chevron-left:before {
    content: '\e87e'
}

.eicon-close:before {
    content: '\e87f'
}

.eicon-file-download:before {
    content: '\e880'
}

.eicon-save:before {
    content: '\e881'
}

.eicon-zoom-in:before {
    content: '\e882'
}

.eicon-shortcode:before {
    content: '\e883'
}

.eicon-nerd:before {
    content: '\e884'
}

.eicon-device-desktop:before {
    content: '\e885'
}

.eicon-device-tablet:before {
    content: '\e886'
}

.eicon-device-mobile:before {
    content: '\e887'
}

.eicon-document-file:before {
    content: '\e888'
}

.eicon-folder-o:before {
    content: '\e889'
}

.eicon-hypster:before {
    content: '\e88a'
}

.eicon-h-align-left:before {
    content: '\e88b'
}

.eicon-h-align-right:before {
    content: '\e88c'
}

.eicon-h-align-center:before {
    content: '\e88d'
}

.eicon-h-align-stretch:before {
    content: '\e88e'
}

.eicon-v-align-top:before {
    content: '\e88f'
}

.eicon-v-align-bottom:before {
    content: '\e890'
}

.eicon-v-align-middle:before {
    content: '\e891'
}

.eicon-v-align-stretch:before {
    content: '\e892'
}

.eicon-pro-icon:before {
    content: '\e893'
}

.eicon-mail:before {
    content: '\e894'
}

.eicon-lock-user:before {
    content: '\e895'
}

.eicon-testimonial-carousel:before {
    content: '\e896'
}

.eicon-media-carousel:before {
    content: '\e897'
}

.eicon-section:before {
    content: '\e898'
}

.eicon-column:before {
    content: '\e899'
}

.eicon-edit:before {
    content: '\e89a'
}

.eicon-clone:before {
    content: '\e89b'
}

.eicon-trash:before {
    content: '\e89c'
}

.eicon-play:before {
    content: '\e89d'
}

.eicon-angle-right:before {
    content: '\e89e'
}

.eicon-angle-left:before {
    content: '\e89f'
}

.eicon-animated-headline:before {
    content: '\e8a0'
}

.eicon-menu-toggle:before {
    content: '\e8a1'
}

.eicon-fb-embed:before {
    content: '\e8a2'
}

.eicon-fb-feed:before {
    content: '\e8a3'
}

.eicon-twitter-embed:before {
    content: '\e8a4'
}

.eicon-twitter-feed:before {
    content: '\e8a5'
}

.eicon-sync:before {
    content: '\e8a6'
}

.eicon-import-export:before {
    content: '\e8a7'
}

.eicon-check-circle:before {
    content: '\e8a8'
}

.eicon-library-save:before {
    content: '\e8a9'
}

.eicon-library-download:before {
    content: '\e8aa'
}

.eicon-insert:before {
    content: '\e8ab'
}

.eicon-preview-medium:before {
    content: '\e8ac'
}

.eicon-sort-down:before {
    content: '\e8ad'
}

.eicon-sort-up:before {
    content: '\e8ae'
}

.eicon-heading:before {
    content: '\e8af'
}

.eicon-logo:before {
    content: '\e8b0'
}

.eicon-meta-data:before {
    content: '\e8b1'
}

.eicon-post-content:before {
    content: '\e8b2'
}

.eicon-post-excerpt:before {
    content: '\e8b3'
}

.eicon-post-navigation:before {
    content: '\e8b4'
}

.eicon-yoast:before {
    content: '\e8b5'
}

.eicon-nerd-chuckle:before {
    content: '\e8b6'
}

.eicon-nerd-wink:before {
    content: '\e8b7'
}

.eicon-comments:before {
    content: '\e8b8'
}

.eicon-download-circle-o:before {
    content: '\e8b9'
}

.eicon-library-upload:before {
    content: '\e8ba'
}

.eicon-save-o:before {
    content: '\e8bb'
}

.eicon-upload-circle-o:before {
    content: '\e8bc'
}

.eicon-ellipsis-h:before {
    content: '\e8bd'
}

.eicon-ellipsis-v:before {
    content: '\e8be'
}

.eicon-arrow-left:before {
    content: '\e8bf'
}

.eicon-arrow-right:before {
    content: '\e8c0'
}

.eicon-arrow-up:before {
    content: '\e8c1'
}

.eicon-arrow-down:before {
    content: '\e8c2'
}

.eicon-play-o:before {
    content: '\e8c3'
}

.eicon-archive-posts:before {
    content: '\e8c4'
}

.eicon-archive-title:before {
    content: '\e8c5'
}

.eicon-featured-image:before {
    content: '\e8c6'
}

.eicon-post-info:before {
    content: '\e8c7'
}

.eicon-post-title:before {
    content: '\e8c8'
}

.eicon-site-logo:before {
    content: '\e8c9'
}

.eicon-site-search:before {
    content: '\e8ca'
}

.eicon-site-title:before {
    content: '\e8cb'
}

.eicon-plus-square:before {
    content: '\e8cc'
}

.eicon-minus-square:before {
    content: '\e8cd'
}

.eicon-cloud-check:before {
    content: '\e8ce'
}

.eicon-drag-n-drop:before {
    content: '\e8cf'
}

.eicon-welcome:before {
    content: '\e8d0'
}

.eicon-handle:before {
    content: '\e8d1'
}

.eicon-cart:before {
    content: '\e8d2'
}

.eicon-product-add-to-cart:before {
    content: '\e8d3'
}

.eicon-product-breadcrumbs:before {
    content: '\e8d4'
}

.eicon-product-categories:before {
    content: '\e8d5'
}

.eicon-product-description:before {
    content: '\e8d6'
}

.eicon-product-images:before {
    content: '\e8d7'
}

.eicon-product-info:before {
    content: '\e8d8'
}

.eicon-product-meta:before {
    content: '\e8d9'
}

.eicon-product-pages:before {
    content: '\e8da'
}

.eicon-product-price:before {
    content: '\e8db'
}

.eicon-product-rating:before {
    content: '\e8dc'
}

.eicon-product-related:before {
    content: '\e8dd'
}

.eicon-product-stock:before {
    content: '\e8de'
}

.eicon-product-tabs:before {
    content: '\e8df'
}

.eicon-product-title:before {
    content: '\e8e0'
}

.eicon-product-upsell:before {
    content: '\e8e1'
}

.eicon-products:before {
    content: '\e8e2'
}

.eicon-bag-light:before {
    content: '\e8e3'
}

.eicon-bag-medium:before {
    content: '\e8e4'
}

.eicon-bag-solid:before {
    content: '\e8e5'
}

.eicon-basket-light:before {
    content: '\e8e6'
}

.eicon-basket-medium:before {
    content: '\e8e7'
}

.eicon-basket-solid:before {
    content: '\e8e8'
}

.eicon-cart-light:before {
    content: '\e8e9'
}

.eicon-cart-medium:before {
    content: '\e8ea'
}

.eicon-cart-solid:before {
    content: '\e8eb'
}

.eicon-exchange:before {
    content: '\e8ec'
}

.eicon-preview-thin:before {
    content: '\e8ed'
}

.eicon-device-laptop:before {
    content: '\e8ee'
}

.eicon-collapse:before {
    content: '\e8ef'
}

.eicon-expand:before {
    content: '\e8f0'
}

.eicon-navigator:before {
    content: '\e8f1'
}

.eicon-plug:before {
    content: '\e8f2'
}

.eicon-dashboard:before {
    content: '\e8f3'
}

.eicon-typography:before {
    content: '\e8f4'
}

.eicon-info-circle-o:before {
    content: '\e8f5'
}

.eicon-integration:before {
    content: '\e8f6'
}

.eicon-plus-circle-o:before {
    content: '\e8f7'
}

.eicon-rating:before {
    content: '\e8f8'
}

.eicon-review:before {
    content: '\e8f9'
}

.eicon-tools:before {
    content: '\e8fa'
}

.eicon-loading:before {
    content: '\e8fb'
}

.eicon-sitemap:before {
    content: '\e8fc'
}

.eicon-click:before {
    content: '\e8fd'
}

.eicon-clock:before {
    content: '\e8fe'
}

.eicon-library-open:before {
    content: '\e8ff'
}

.eicon-warning:before {
    content: '\e900'
}

.eicon-flow:before {
    content: '\e901'
}

.eicon-cursor-move:before {
    content: '\e902'
}

.eicon-arrow-circle-left:before {
    content: '\e903'
}

.eicon-flash:before {
    content: '\e904'
}

.eicon-redo:before {
    content: '\e905'
}

.eicon-ban:before {
    content: '\e906'
}

.eicon-barcode:before {
    content: '\e907'
}

.eicon-calendar:before {
    content: '\e908'
}

.eicon-caret-left:before {
    content: '\e909'
}

.eicon-caret-right:before {
    content: '\e90a'
}

.eicon-caret-up:before {
    content: '\e90b'
}

.eicon-chain-broken:before {
    content: '\e90c'
}

.eicon-check-circle-o:before {
    content: '\e90d'
}

.eicon-check:before {
    content: '\e90e'
}

.eicon-chevron-double-left:before {
    content: '\e90f'
}

.eicon-chevron-double-right:before {
    content: '\e910'
}

.eicon-undo:before {
    content: '\e911'
}

.eicon-filter:before {
    content: '\e912'
}

.eicon-circle-o:before {
    content: '\e913'
}

.eicon-circle:before {
    content: '\e914'
}

.eicon-clock-o:before {
    content: '\e915'
}

.eicon-cog:before {
    content: '\e916'
}

.eicon-cogs:before {
    content: '\e917'
}

.eicon-commenting-o:before {
    content: '\e918'
}

.eicon-copy:before {
    content: '\e919'
}

.eicon-database:before {
    content: '\e91a'
}

.eicon-dot-circle-o:before {
    content: '\e91b'
}

.eicon-envelope:before {
    content: '\e91c'
}

.eicon-external-link-square:before {
    content: '\e91d'
}

.eicon-eyedropper:before {
    content: '\e91e'
}

.eicon-folder:before {
    content: '\e91f'
}

.eicon-font:before {
    content: '\e920'
}

.eicon-adjust:before {
    content: '\e921'
}

.eicon-lightbox:before {
    content: '\e922'
}

.eicon-heart-o:before {
    content: '\e923'
}

.eicon-history:before {
    content: '\e924'
}

.eicon-image-bold:before {
    content: '\e925'
}

.eicon-info-circle:before {
    content: '\e926'
}

.eicon-link:before {
    content: '\e927'
}

.eicon-long-arrow-left:before {
    content: '\e928'
}

.eicon-long-arrow-right:before {
    content: '\e929'
}

.eicon-caret-down:before {
    content: '\e92a'
}

.eicon-paint-brush:before {
    content: '\e92b'
}

.eicon-pencil:before {
    content: '\e92c'
}

.eicon-plus-circle:before {
    content: '\e92d'
}

.eicon-zoom-in-bold:before {
    content: '\e92e'
}

.eicon-sort-amount-desc:before {
    content: '\e92f'
}

.eicon-sign-out:before {
    content: '\e930'
}

.eicon-spinner:before {
    content: '\e931'
}

.eicon-square:before {
    content: '\e932'
}

.eicon-star-o:before {
    content: '\e933'
}

.eicon-star:before {
    content: '\e934'
}

.eicon-text-align-justify:before {
    content: '\e935'
}

.eicon-text-align-center:before {
    content: '\e936'
}

.eicon-tags:before {
    content: '\e937'
}

.eicon-text-align-left:before {
    content: '\e938'
}

.eicon-text-align-right:before {
    content: '\e939'
}

.eicon-close-circle:before {
    content: '\e93a'
}

.eicon-trash-o:before {
    content: '\e93b'
}

.eicon-font-awesome:before {
    content: '\e93c'
}

.eicon-user-circle-o:before {
    content: '\e93d'
}

.eicon-video-camera:before {
    content: '\e93e'
}

.eicon-heart:before {
    content: '\e93f'
}

.eicon-wrench:before {
    content: '\e940'
}

.eicon-help:before {
    content: '\e941'
}

.eicon-help-o:before {
    content: '\e942'
}

.eicon-zoom-out-bold:before {
    content: '\e943'
}

.eicon-plus-square-o:before {
    content: '\e944'
}

.eicon-minus-square-o:before {
    content: '\e945'
}

.eicon-minus-circle:before {
    content: '\e946'
}

.eicon-minus-circle-o:before {
    content: '\e947'
}

.eicon-code-bold:before {
    content: '\e948'
}

.eicon-cloud-upload:before {
    content: '\e949'
}

.eicon-search-bold:before {
    content: '\e94a'
}

.eicon-map-pin:before {
    content: '\e94b'
}

.eicon-meetup:before {
    content: '\e94c'
}

.eicon-slideshow:before {
    content: '\e94d'
}

.eicon-t-letter-bold:before {
    content: '\e94e'
}

.eicon-preferences:before {
    content: '\e94f'
}

.eicon-table-of-contents:before {
    content: '\e950'
}

.eicon-tv:before {
    content: '\e951'
}

.eicon-upload:before {
    content: '\e952'
}

.eicon-instagram-comments:before {
    content: '\e953'
}

.eicon-instagram-nested-gallery:before {
    content: '\e954'
}

.eicon-instagram-post:before {
    content: '\e955'
}

.eicon-instagram-video:before {
    content: '\e956'
}

.eicon-instagram-gallery:before {
    content: '\e957'
}

.eicon-instagram-likes:before {
    content: '\e958'
}

.eicon-facebook:before {
    content: '\e959'
}

.eicon-twitter:before {
    content: '\e95a'
}

.eicon-pinterest:before {
    content: '\e95b'
}

.eicon-frame-expand:before {
    content: '\e95c'
}

.eicon-frame-minimize:before {
    content: '\e95d'
}

.eicon-archive:before {
    content: '\e95e'
}

.eicon-colors-typography:before {
    content: '\e95f'
}

.eicon-custom:before {
    content: '\e960'
}

.eicon-footer:before {
    content: '\e961'
}

.eicon-header:before {
    content: '\e962'
}

.eicon-layout-settings:before {
    content: '\e963'
}

.eicon-lightbox-expand:before {
    content: '\e964'
}

.eicon-error-404:before {
    content: '\e965'
}

.eicon-theme-style:before {
    content: '\e966'
}

.eicon-search-results:before {
    content: '\e967'
}

.eicon-single-post:before {
    content: '\e968'
}

.eicon-site-identity:before {
    content: '\e969'
}

.eicon-theme-builder:before {
    content: '\e96a'
}

.eicon-download-bold:before {
    content: '\e96b'
}

.eicon-share-arrow:before {
    content: '\e96c'
}

.eicon-global-settings:before {
    content: '\e96d'
}

.eicon-user-preferences:before {
    content: '\e96e'
}

.eicon-lock:before {
    content: '\e96f'
}

.eicon-export-kit:before {
    content: '\e970'
}

.eicon-import-kit:before {
    content: '\e971'
}

.eicon-lottie:before {
    content: '\e972'
}

.eicon-products-archive:before {
    content: '\e973'
}

.eicon-single-product:before {
    content: '\e974'
}

.eicon-disable-trash-o:before {
    content: '\e975'
}

.eicon-single-page:before {
    content: '\e976'
}

.eicon-wordpress-light:before {
    content: '\e977'
}

.eicon-cogs-check:before {
    content: '\e978'
}

.eicon-custom-css:before {
    content: '\e979'
}

.eicon-global-colors:before {
    content: '\e97a'
}

.eicon-globe:before {
    content: '\e97b'
}

.eicon-typography-1:before {
    content: '\e97c'
}

.eicon-background:before {
    content: '\e97d'
}

.eicon-device-responsive:before {
    content: '\e97e'
}

.eicon-device-wide:before {
    content: '\e97f'
}

.eicon-code-highlight:before {
    content: '\e980'
}

.eicon-video-playlist:before {
    content: '\e981'
}

.eicon-download-kit:before {
    content: '\e982'
}

.eicon-kit-details:before {
    content: '\e983'
}

.eicon-kit-parts:before {
    content: '\e984'
}

.eicon-kit-upload:before {
    content: '\e985'
}

.eicon-kit-plugins:before {
    content: '\e986'
}

.eicon-kit-upload-alt:before {
    content: '\e987'
}

.eicon-hotspot:before {
    content: '\e988'
}

.eicon-paypal-button:before {
    content: '\e989'
}

.eicon-shape:before {
    content: '\e98a'
}

.eicon-wordart:before {
    content: '\e98b'
}

.eicon-checkout:before {
    content: '\e98c'
}

.eicon-container:before {
    content: '\e98d'
}

.eicon-flip:before {
    content: '\e98e'
}

.eicon-info:before {
    content: '\e98f'
}

.eicon-my-account:before {
    content: '\e990'
}

.eicon-purchase-summary:before {
    content: '\e991'
}

.eicon-page-transition:before {
    content: '\e992'
}

.eicon-spotify:before {
    content: '\e993'
}

.eicon-stripe-button:before {
    content: '\e994'
}

.eicon-woo-settings:before {
    content: '\e995'
}

.eicon-woo-cart:before {
    content: '\e996'
}

.eicon-grow:before {
    content: '\e997'
}

.eicon-order-end:before {
    content: '\e998'
}

.eicon-nowrap:before {
    content: '\e999'
}

.eicon-order-start:before {
    content: '\e99a'
}

.eicon-progress-tracker:before {
    content: '\e99b'
}

.eicon-shrink:before {
    content: '\e99c'
}

.eicon-wrap:before {
    content: '\e99d'
}

.eicon-align-center-h:before {
    content: '\e99e'
}

.eicon-align-center-v:before {
    content: '\e99f'
}

.eicon-align-end-h:before {
    content: '\e9a0'
}

.eicon-align-end-v:before {
    content: '\e9a1'
}

.eicon-align-start-h:before {
    content: '\e9a2'
}

.eicon-align-start-v:before {
    content: '\e9a3'
}

.eicon-align-stretch-h:before {
    content: '\e9a4'
}

.eicon-align-stretch-v:before {
    content: '\e9a5'
}

.eicon-justify-center-h:before {
    content: '\e9a6'
}

.eicon-justify-center-v:before {
    content: '\e9a7'
}

.eicon-justify-end-h:before {
    content: '\e9a8'
}

.eicon-justify-end-v:before {
    content: '\e9a9'
}

.eicon-justify-space-around-h:before {
    content: '\e9aa'
}

.eicon-justify-space-around-v:before {
    content: '\e9ab'
}

.eicon-justify-space-between-h:before {
    content: '\e9ac'
}

.eicon-justify-space-between-v:before {
    content: '\e9ad'
}

.eicon-justify-space-evenly-h:before {
    content: '\e9ae'
}

.eicon-justify-space-evenly-v:before {
    content: '\e9af'
}

.eicon-justify-start-h:before {
    content: '\e9b0'
}

.eicon-justify-start-v:before {
    content: '\e9b1'
}

.eicon-woocommerce-cross-sells:before {
    content: '\e9b2'
}

.eicon-woocommerce-notices:before {
    content: '\e9b3'
}

.eicon-inner-container:before {
    content: '\e9b4'
}

.eicon-warning-full:before {
    content: '\e9b5'
}

.eicon-exit:before {
    content: '\e9b6'
}

.eicon-eye:before {
    content: '\e8ac'
}

.eicon-elementor-square:before {
    content: '\e813'
}