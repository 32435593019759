.elementor-6 .elementor-element.elementor-element-247439f>.elementor-container {
    min-height: 700px;
}

.elementor-6 .elementor-element.elementor-element-247439f {
    overflow: hidden;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 60px 0px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-247439f:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-247439f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../assests/images/05/hero_circles.svg");
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: auto;
}

.elementor-6 .elementor-element.elementor-element-247439f>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-6afece6>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 15px;
}

.elementor-6 .elementor-element.elementor-element-6afece6>.elementor-element-populated {
    margin: 10% 0% 0% 17%;
    --e-column-margin-right: 0%;
    --e-column-margin-left: 17%;
}

.elementor-6 .elementor-element.elementor-element-9da2591 .elementor-heading-title {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 52px;
    font-weight: 600;
    line-height: 1.2em;
}

.elementor-6 .elementor-element.elementor-element-618a378 .elementor-heading-title {
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.2em;
}

.elementor-6 .elementor-element.elementor-element-1864738 {
    color: var(--e-global-color-d68e133);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-1864738>.elementor-widget-container {
    margin: 0% 20% 0% 0%;
}

.elementor-6 .elementor-element.elementor-element-0b0218a .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-6 .elementor-element.elementor-element-0b0218a .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-6 .elementor-element.elementor-element-0b0218a .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px 5px 5px 5px;
    padding: 15px 25px 15px 20px;
}

.elementor-6 .elementor-element.elementor-element-0b0218a>.elementor-widget-container {
    padding: 0px 10px 10px 0px;
    /* background-image: url("../../assests/images/05/arrow_design.svg"); */
    background-position: 230px 0px;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-0b0218a {
    width: auto;
    max-width: auto;
}

.elementor-6 .elementor-element.elementor-element-01eeb42 .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-6 .elementor-element.elementor-element-01eeb42 .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-6 .elementor-element.elementor-element-01eeb42 .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    background-color: transparent;
    background-image: linear-gradient(180deg, var(--e-global-color-ac969e5) 0%, #54E37F 100%);
    border-radius: 5px 5px 5px 5px;
    padding: 16px 25px 15px 20px;
}

.elementor-6 .elementor-element.elementor-element-01eeb42 .elementor-button:hover,
.elementor-6 .elementor-element.elementor-element-01eeb42 .elementor-button:focus {
    background-color: transparent;
    background-image: linear-gradient(180deg, var(--e-global-color-ac969e5) 0%, var(--e-global-color-ac969e5) 100%);
}

.elementor-6 .elementor-element.elementor-element-01eeb42>.elementor-widget-container {
    padding: 0px 100px 60px 0px;
    /* background-image: url("../../assests/images/05/arrow_design.svg"); */
    background-position: 120px 0px;
    background-repeat: no-repeat;
    background-size: 150px auto;
}

.elementor-6 .elementor-element.elementor-element-01eeb42 {
    width: auto;
    max-width: auto;
}

.elementor-6 .elementor-element.elementor-element-d8c8e57 {
    text-align: right;
    top: 0px;
}

body:not(.rtl) .elementor-6 .elementor-element.elementor-element-d8c8e57 {
    left: 0px;
}

body.rtl .elementor-6 .elementor-element.elementor-element-d8c8e57 {
    right: 0px;
}

.elementor-6 .elementor-element.elementor-element-896c3c6>.elementor-container {
    max-width: 1540px;
}

.elementor-6 .elementor-element.elementor-element-896c3c6,
.elementor-6 .elementor-element.elementor-element-896c3c6>.elementor-background-overlay {
    border-radius: 0px 100px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-896c3c6 {
    max-width: 1990px;
    margin-top: 40px;
    margin-bottom: 0px;
    padding: 50px 50px 50px 50px;
    z-index: 2;
}

.elementor-6 .elementor-element.elementor-element-2170611.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-primary);
}

.elementor-6 .elementor-element.elementor-element-2170611.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-2170611.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-primary);
    color: var(--e-global-color-primary);
    border-color: var(--e-global-color-primary);
}

.elementor-6 .elementor-element.elementor-element-2170611 {
    --icon-box-icon-margin: 7px;
}

.elementor-6 .elementor-element.elementor-element-2170611 .elementor-icon {
    font-size: 26px;
}

.elementor-6 .elementor-element.elementor-element-2170611 .elementor-icon-box-wrapper {
    text-align: left;
}

.elementor-6 .elementor-element.elementor-element-2170611 .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-2170611 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-2170611 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-2170611 .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-2170611>.elementor-widget-container {
    margin: 0px 40px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-1dfde13.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-primary);
}

.elementor-6 .elementor-element.elementor-element-1dfde13.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-1dfde13.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-primary);
    color: var(--e-global-color-primary);
    border-color: var(--e-global-color-primary);
}

.elementor-6 .elementor-element.elementor-element-1dfde13 {
    --icon-box-icon-margin: 7px;
}

.elementor-6 .elementor-element.elementor-element-1dfde13 .elementor-icon {
    font-size: 26px;
}

.elementor-6 .elementor-element.elementor-element-1dfde13 .elementor-icon-box-wrapper {
    text-align: left;
}

.elementor-6 .elementor-element.elementor-element-1dfde13 .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-1dfde13 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-1dfde13 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-1dfde13 .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-1dfde13>.elementor-widget-container {
    margin: 0px 30px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-937208e.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-primary);
}

.elementor-6 .elementor-element.elementor-element-937208e.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-937208e.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-primary);
    color: var(--e-global-color-primary);
    border-color: var(--e-global-color-primary);
 
}

.elementor-6 .elementor-element.elementor-element-937208e {
    --icon-box-icon-margin: 7px;
}

.elementor-6 .elementor-element.elementor-element-937208e .elementor-icon {
    font-size: 26px;
}

.elementor-6 .elementor-element.elementor-element-937208e .elementor-icon-box-wrapper {
    text-align: left;
}

.elementor-6 .elementor-element.elementor-element-937208e .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-937208e .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-937208e .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-937208e .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-937208e>.elementor-widget-container {
    margin: 0px 50px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-c7bfe75:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-c7bfe75>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../assests/images/05/circles.svg");
    background-position: 0px 50vh;
    background-repeat: no-repeat;
    background-size: auto;
}

.elementor-6 .elementor-element.elementor-element-c7bfe75 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 60px 0px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-c7bfe75>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-88274ec.elementor-column>.elementor-widget-wrap {
    justify-content: center;
}

.elementor-6 .elementor-element.elementor-element-b20a35f {
    text-align: center;
    width: auto;
    max-width: auto;
}

.elementor-6 .elementor-element.elementor-element-b20a35f .elementor-heading-title {
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-b20a35f>.elementor-widget-container {
    padding: 25px 30px 0px 30px;
    background-image: url("../../assests/images/05/pric_head_circles.svg");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
}

.elementor-6 .elementor-element.elementor-element-bf87250 .elementor-icon-wrapper {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-bf87250 .elementor-icon i,
.elementor-6 .elementor-element.elementor-element-bf87250 .elementor-icon svg {
    transform: rotate(0deg);
}

body:not(.rtl) .elementor-6 .elementor-element.elementor-element-bf87250 {
    right: 0px;
}

body.rtl .elementor-6 .elementor-element.elementor-element-bf87250 {
    left: 0px;
}

.elementor-6 .elementor-element.elementor-element-bf87250 {
    top: 0px;
}

.elementor-6 .elementor-element.elementor-element-330d8c7 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-330d8c7 .elementor-heading-title {
    color: var(--e-global-color-d68e133);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-9b13b74>.elementor-container {
    max-width: 1115px;
}

.elementor-6 .elementor-element.elementor-element-9b13b74:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-9b13b74>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(240deg, #E9EDF4 0%, #EEEFF25C 100%);
}

.elementor-6 .elementor-element.elementor-element-9b13b74 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 20px 0px 20px 0px;
}

.elementor-6 .elementor-element.elementor-element-9b13b74>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-d4750ac.elementor-column>.elementor-widget-wrap {
    justify-content: center;
}

.elementor-6 .elementor-element.elementor-element-d4750ac>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 40px;
}

.elementor-6 .elementor-element.elementor-element-a998abd {
    text-align: center;
    width: auto;
    max-width: auto;
}

.elementor-6 .elementor-element.elementor-element-a998abd .elementor-heading-title {
    color: var(--e-global-color-primary);
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 600;
    line-height: 1.2em;
}

.elementor-6 .elementor-element.elementor-element-a998abd>.elementor-widget-container {
    padding: 30px 40px 20px 40px;
    background-image: url("../../assests/images/06/headingBG.svg");
    background-position: top center;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-title,
.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-title:before,
.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-title:after,
.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-content,
.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tabs-content-wrapper {
    border-width: 0px;
}

.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-title.elementor-active,
.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-title.elementor-active a {
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-title {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-7087b48 .elementor-tab-content {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-9821482>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-6 .elementor-element.elementor-element-9821482:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-9821482>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../assests/images/05/pricing_bg.svg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: auto;
}

.elementor-6 .elementor-element.elementor-element-9821482 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 40px 0px 40px 0px;
}

.elementor-6 .elementor-element.elementor-element-9821482>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-e03ffc9:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-element-populated,
.elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-element-populated>.elementor-background-overlay,
.elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-background-slideshow {
    border-radius: 80px 80px 80px 80px;
}

.elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 40px 40px 40px;
}

.elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-c0671f7 {
    --icon-box-icon-margin: 15px;
}

.elementor-6 .elementor-element.elementor-element-c0671f7 .elementor-icon {
    font-size: 45px;
    border-radius: 20px 20px 20px 20px;
}

.elementor-6 .elementor-element.elementor-element-c0671f7 .elementor-icon-box-title {
    margin-bottom: 5px;
    color: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-c0671f7 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-c0671f7 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-c0671f7 .elementor-icon-box-description {
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-c0671f7>.elementor-widget-container {
    padding: 15px 15px 15px 15px;
    background-color: #F7F8FB;
    border-radius: 24px 24px 24px 24px;
}

.elementor-6 .elementor-element.elementor-element-39c8543 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-39c8543 .elementor-heading-title {
    font-family: "Fira Sans", Sans;
    font-size: 48px;
    font-weight: 700;
}

.elementor-6 .elementor-element.elementor-element-39c8543>.elementor-widget-container {
    margin: 15px 0px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-28592ba .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-6 .elementor-element.elementor-element-28592ba .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-6 .elementor-element.elementor-element-28592ba .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px 6px 6px 6px;
    padding: 20px 50px 20px 50px;
}

.elementor-6 .elementor-element.elementor-element-b6acfd4.elementor-column>.elementor-widget-wrap {
    justify-content: center;
}

.elementor-6 .elementor-element.elementor-element-b6acfd4:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    /* background-color: #233146; */
    background-color: #6CD88D;
}

.elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-element-populated,
.elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-element-populated>.elementor-background-overlay,
.elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-background-slideshow {
    border-radius: 80px 80px 80px 80px;
}

.elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 40px 40px 40px;
}

.elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-b6acfd4 {
    z-index: 1;
}

.elementor-6 .elementor-element.elementor-element-db95be8 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-db95be8 .elementor-heading-title {
    color: var(--e-global-color-ac969e5);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-7862f42.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-7862f42.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-7862f42.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-29f88a8);
    color: var(--e-global-color-29f88a8);
    border-color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-7862f42 {
    --icon-box-icon-margin: 15px;
}

.elementor-6 .elementor-element.elementor-element-7862f42 .elementor-icon {
    font-size: 45px;
    border-radius: 20px 20px 20px 20px;
}

.elementor-6 .elementor-element.elementor-element-7862f42 .elementor-icon-box-title {
    margin-bottom: 5px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-7862f42 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-7862f42 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-7862f42 .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-7862f42>.elementor-widget-container {
    padding: 15px 15px 15px 15px;
    background-color: #3C4D6A;
    border-radius: 24px 24px 24px 24px;
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c .elementor-icon-wrapper {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-stacked .elementor-icon {
    background-color: #3C4D6A;
    color: var(--e-global-color-ac969e5);
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-default .elementor-icon {
    color: #3C4D6A;
    border-color: #3C4D6A;
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-default .elementor-icon svg {
    fill: #3C4D6A;
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-framed .elementor-icon {
    background-color: var(--e-global-color-ac969e5);
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c.elementor-view-stacked .elementor-icon svg {
    fill: var(--e-global-color-ac969e5);
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c .elementor-icon {
    font-size: 20px;
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c .elementor-icon i,
.elementor-6 .elementor-element.elementor-element-c5d6b2c .elementor-icon svg {
    transform: rotate(0deg);
}

.elementor-6 .elementor-element.elementor-element-c5d6b2c>.elementor-widget-container {
    margin: 4px 0px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-40f0e5f.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-40f0e5f.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-40f0e5f.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-29f88a8);
    color: var(--e-global-color-29f88a8);
    border-color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-40f0e5f {
    --icon-box-icon-margin: 15px;
}

.elementor-6 .elementor-element.elementor-element-40f0e5f .elementor-icon {
    font-size: 45px;
    border-radius: 20px 20px 20px 20px;
}

.elementor-6 .elementor-element.elementor-element-40f0e5f .elementor-icon-box-title {
    margin-bottom: 5px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-40f0e5f .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-40f0e5f .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-40f0e5f .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-40f0e5f>.elementor-widget-container {
    padding: 15px 15px 15px 15px;
    background-color: #3C4D6A;
    border-radius: 24px 24px 24px 24px;
}

.elementor-6 .elementor-element.elementor-element-80ab700 {
    text-align: center;
    width: auto;
    max-width: auto;
}

.elementor-6 .elementor-element.elementor-element-80ab700 .elementor-heading-title {
    color: var(--e-global-color-29f88a8);
    font-family: "Fira Sans", Sans;
    font-size: 48px;
    font-weight: 700;
}

.elementor-6 .elementor-element.elementor-element-80ab700>.elementor-widget-container {
    margin: 30px 0px 20px 0px;
}

.elementor-6 .elementor-element.elementor-element-9258fca .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-6 .elementor-element.elementor-element-9258fca .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-6 .elementor-element.elementor-element-9258fca .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px 6px 6px 6px;
    padding: 20px 50px 20px 50px;
}

.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-6 .elementor-element.elementor-element-1bc5ca1:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-element-populated,
.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-element-populated>.elementor-background-overlay,
.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-background-slideshow {
    border-radius: 80px 80px 80px 80px;
}

.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 40px 40px 40px;
}

.elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-8fc2120 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-8fc2120 .elementor-heading-title {
    color: var(--e-global-color-d68e133);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-8fc2120>.elementor-widget-container {
    margin: 0px 0px 15px 0px;
}

.elementor-6 .elementor-element.elementor-element-bf9d705 {
    --icon-box-icon-margin: 10px;
}

.elementor-6 .elementor-element.elementor-element-bf9d705 .elementor-icon {
    font-size: 28px;
    border-radius: 12px 12px 12px 12px;
}

.elementor-6 .elementor-element.elementor-element-bf9d705 .elementor-icon-box-title {
    margin-bottom: 2px;
    color: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-bf9d705 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-bf9d705 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-bf9d705 .elementor-icon-box-description {
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-bf9d705>.elementor-widget-container {
    padding: 15px 15px 15px 15px;
    background-color: #F7F8FB;
    border-radius: 24px 24px 24px 24px;
}

.elementor-6 .elementor-element.elementor-element-6f4b00b .elementor-icon-wrapper {
    text-align: right;
}

.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-stacked .elementor-icon {
    background-color: #6CD88D;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-default .elementor-icon {
    color: #6CD88D;
    border-color: #6CD88D;
}

.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-default .elementor-icon svg {
    fill: #6CD88D;
}

.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-framed .elementor-icon {
    background-color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-6f4b00b.elementor-view-stacked .elementor-icon svg {
    fill: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-6f4b00b .elementor-icon {
    font-size: 20px;
}

.elementor-6 .elementor-element.elementor-element-6f4b00b .elementor-icon i,
.elementor-6 .elementor-element.elementor-element-6f4b00b .elementor-icon svg {
    transform: rotate(0deg);
}

.elementor-6 .elementor-element.elementor-element-6f4b00b>.elementor-widget-container {
    margin: -10px 30px -15px 0px;
}

.elementor-6 .elementor-element.elementor-element-6f4b00b {
    z-index: 2;
}

.elementor-6 .elementor-element.elementor-element-e9b1067 {
    --icon-box-icon-margin: 10px;
}

.elementor-6 .elementor-element.elementor-element-e9b1067 .elementor-icon {
    font-size: 28px;
    border-radius: 12px 12px 12px 12px;
}

.elementor-6 .elementor-element.elementor-element-e9b1067 .elementor-icon-box-title {
    margin-bottom: 2px;
    color: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-e9b1067 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-e9b1067 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-e9b1067 .elementor-icon-box-description {
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-e9b1067>.elementor-widget-container {
    padding: 15px 15px 15px 15px;
    background-color: #F7F8FB;
    border-radius: 24px 24px 24px 24px;
}

.elementor-6 .elementor-element.elementor-element-567123a .elementor-icon-wrapper {
    text-align: right;
}

.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-stacked .elementor-icon {
    background-color: #6CD88D;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-default .elementor-icon {
    color: #6CD88D;
    border-color: #6CD88D;
}

.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-default .elementor-icon svg {
    fill: #6CD88D;
}

.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-framed .elementor-icon {
    background-color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-567123a.elementor-view-stacked .elementor-icon svg {
    fill: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-567123a .elementor-icon {
    font-size: 20px;
}

.elementor-6 .elementor-element.elementor-element-567123a .elementor-icon i,
.elementor-6 .elementor-element.elementor-element-567123a .elementor-icon svg {
    transform: rotate(0deg);
}

.elementor-6 .elementor-element.elementor-element-567123a>.elementor-widget-container {
    margin: -10px 30px -15px 0px;
}

.elementor-6 .elementor-element.elementor-element-567123a {
    z-index: 2;
}

.elementor-6 .elementor-element.elementor-element-39012f0 {
    --icon-box-icon-margin: 10px;
}

.elementor-6 .elementor-element.elementor-element-39012f0 .elementor-icon {
    font-size: 28px;
    border-radius: 12px 12px 12px 12px;
}

.elementor-6 .elementor-element.elementor-element-39012f0 .elementor-icon-box-title {
    margin-bottom: 2px;
    color: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-39012f0 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-39012f0 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-39012f0 .elementor-icon-box-description {
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-39012f0>.elementor-widget-container {
    padding: 15px 15px 15px 15px;
    background-color: #F7F8FB;
    border-radius: 24px 24px 24px 24px;
}

.elementor-6 .elementor-element.elementor-element-cb5a2b4 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-cb5a2b4 .elementor-heading-title {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 48px;
    font-weight: 700;
}

.elementor-6 .elementor-element.elementor-element-cb5a2b4>.elementor-widget-container {
    margin: 30px 0px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-00e7abe .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-6 .elementor-element.elementor-element-00e7abe .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-6 .elementor-element.elementor-element-00e7abe .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px 6px 6px 6px;
    padding: 20px 50px 20px 50px;
}

.elementor-6 .elementor-element.elementor-element-00e7abe>.elementor-widget-container {
    margin: 20px 20px 20px 20px;
}

.elementor-6 .elementor-element.elementor-element-5bb7bb0e:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-5bb7bb0e>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: darkgreen;
   
    /* background-image: linear-gradient(120deg, var(--e-global-color-secondary) 0%, var(--e-global-color-secondary) 100%); */
    /* background-image: linear-gradient(120deg, var(--e-global-color-primary) 0%, var(--e-global-color-859d4d9) 100%); */
}

.elementor-6 .elementor-element.elementor-element-5bb7bb0e {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 80px 0px 80px 0px;
}

.elementor-6 .elementor-element.elementor-element-5bb7bb0e>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-238d1431 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-238d1431 .elementor-heading-title {
    color: var(--e-global-color-29f88a8);
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-238d1431>.elementor-widget-container {
    padding: 40px 0px 40px 0px;
    background-image: url("../../assests/images/05/how_HBG.svg");
    background-position: center center;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-7c53c1e5 .elementor-icon-wrapper {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-7c53c1e5 .elementor-icon {
    font-size: 150px;
}

.elementor-6 .elementor-element.elementor-element-7c53c1e5 .elementor-icon i,
.elementor-6 .elementor-element.elementor-element-7c53c1e5 .elementor-icon svg {
    transform: rotate(0deg);
}

.elementor-6 .elementor-element.elementor-element-7c53c1e5 {
    width: auto;
    max-width: auto;
    top: -10px;
}

body:not(.rtl) .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
    right: 270px;
}

body.rtl .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
    left: 270px;
}

.elementor-6 .elementor-element.elementor-element-36374419 {
    margin-top: 50px;
    margin-bottom: 80px;
}

.elementor-6 .elementor-element.elementor-element-32b4737.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-32b4737.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-32b4737.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-ecb3566);
    color: var(--e-global-color-ecb3566);
    border-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-32b4737 {
    --icon-box-icon-margin: 29px;
}

.elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon {
    font-size: 44px;
    border-radius: 5px 5px 5px 5px;
}

.elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 24px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-32b4737>.elementor-widget-container {
    padding: 0px 120px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-14de4161 .elementor-icon-wrapper {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-14de4161 .elementor-icon i,
.elementor-6 .elementor-element.elementor-element-14de4161 .elementor-icon svg {
    transform: rotate(0deg);
}

.elementor-6 .elementor-element.elementor-element-14de4161 {
    width: auto;
    max-width: auto;
    bottom: -50px;
}

body:not(.rtl) .elementor-6 .elementor-element.elementor-element-14de4161 {
    right: 20px;
}

body.rtl .elementor-6 .elementor-element.elementor-element-14de4161 {
    left: 20px;
}

.elementor-6 .elementor-element.elementor-element-335c6518.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-335c6518.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-335c6518.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-ecb3566);
    color: var(--e-global-color-ecb3566);
    border-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-335c6518 {
    --icon-box-icon-margin: 29px;
}

.elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon {
    font-size: 44px;
    border-radius: 5px 5px 5px 5px;
}

.elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 24px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-335c6518>.elementor-widget-container {
    margin: 0px 40px 0px 0px;
}

.elementor-6 .elementor-element.elementor-element-14dbe19e.elementor-view-stacked .elementor-icon {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-14dbe19e.elementor-view-framed .elementor-icon,
.elementor-6 .elementor-element.elementor-element-14dbe19e.elementor-view-default .elementor-icon {
    fill: var(--e-global-color-ecb3566);
    color: var(--e-global-color-ecb3566);
    border-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-14dbe19e {
    --icon-box-icon-margin: 29px;
}

.elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon {
    font-size: 50px;
    padding: 20px;
    border-radius: 5px 5px 5px 5px;
}

.elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-title {
    margin-bottom: 10px;
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-title,
.elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-title a {
    font-family: "Fira Sans", Sans;
    font-size: 24px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-description {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-b7c3ce3>.elementor-container {
    max-width: 1115px;
}

.elementor-6 .elementor-element.elementor-element-ac290af>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 40px;
}

.elementor-6 .elementor-element.elementor-element-ac290af>.elementor-element-populated {
    padding: 60px 0px 60px 0px;
}

.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-title,
.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-title:before,
.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-title:after,
.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-content,
.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tabs-content-wrapper {
    border-width: 0px;
}

.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-title.elementor-active,
.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-title.elementor-active a {
    color: var(--e-global-color-29f88a8);
}

.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-title {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-bd1b177 .elementor-tab-content {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-c3b5fab>.elementor-container {
    max-width: 1320px;
}

.elementor-6 .elementor-element.elementor-element-c3b5fab:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-c3b5fab>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-c3b5fab {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 0px 80px 0px;
}

.elementor-6 .elementor-element.elementor-element-c3b5fab>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-6b5b225>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 30px;
}

.elementor-6 .elementor-element.elementor-element-7008fbb {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-7008fbb .elementor-heading-title {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-7008fbb>.elementor-widget-container {
    padding: 40px 0px 10px 0px;
    background-image: url("../../assests/images/05/typeHBG.svg");
    background-position: center center;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-551e68a {
    --e-image-carousel-slides-to-show: 3;
}

.elementor-6 .elementor-element.elementor-element-551e68a .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background: var(--e-global-color-859d4d9);
    opacity: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-551e68a .swiper-pagination-bullet {
    background: var(--e-global-color-ac969e5);
}

.elementor-6 .elementor-element.elementor-element-82fc35f>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-6 .elementor-element.elementor-element-82fc35f:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-82fc35f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-ac969e5);
    background-image: url("../../assests/images/05/testi_bg.webp");
    background-position: bottom left;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-82fc35f {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 60px 0px 60px 0px;
}

.elementor-6 .elementor-element.elementor-element-82fc35f>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-210e459 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-210e459 img {
    max-width: 340px;
    border-radius: 200px 200px 200px 200px;
}

.elementor-6 .elementor-element.elementor-element-210e459>.elementor-widget-container {
    margin: 20px 0px 20px 0px;
}

.elementor-6 .elementor-element.elementor-element-b4e4246>.elementor-element-populated {
    padding: 10px 165px 10px 10px;
}

.elementor-6 .elementor-element.elementor-element-5a8e6b7 .elementor-heading-title {
    color: var(--e-global-color-29f88a8);
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-ecbc93d {
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-7fc73d4e>.elementor-container {
    max-width: 1200px;
}

.elementor-6 .elementor-element.elementor-element-7fc73d4e:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-7fc73d4e>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-7fc73d4e {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 80px 0px 40px 0px;
}

.elementor-6 .elementor-element.elementor-element-7fc73d4e>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-3e673d69>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 30px;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title {
    border-width: 0px;
    background-color: var(--e-global-color-29f88a8);
    padding: 30px 30px 30px 30px;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-content {
    border-width: 0px;
    color: var(--e-global-color-d68e133);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
    padding: 0px 15px 15px 75px;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-toggle-item:not(:last-child) {
    margin-bottom: 15px;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-toggle-title,
.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-toggle-icon {
    color: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-toggle-icon svg {
    fill: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title.elementor-active a,
.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title.elementor-active .elementor-toggle-icon {
    color: var(--e-global-color-859d4d9);
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-toggle-title {
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 600;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title .elementor-toggle-icon i:before {
    color: #1D2A3E80;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title .elementor-toggle-icon svg {
    fill: #1D2A3E80;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title.elementor-active .elementor-toggle-icon i:before {
    color: #1D2A3E80;
}

.elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-title.elementor-active .elementor-toggle-icon svg {
    fill: #1D2A3E80;
}

.elementor-6 .elementor-element.elementor-element-3c4db939>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 10px;
}

.elementor-6 .elementor-element.elementor-element-3c4db939>.elementor-element-populated {
    padding: 0px 0px 0px 30px;
}

.elementor-6 .elementor-element.elementor-element-4b7c528a .elementor-heading-title {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 52px;
    font-weight: 600;
    line-height: 1.2em;
}

.elementor-6 .elementor-element.elementor-element-4b7c528a>.elementor-widget-container {
    margin: 12px 45px 0px 45px;
}

.elementor-6 .elementor-element.elementor-element-b2c8d09 {
    color: var(--e-global-color-d68e133);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-b2c8d09>.elementor-widget-container {
    margin: 0px 45px 0px 45px;
}

.elementor-6 .elementor-element.elementor-element-1a4be35 {
    text-align: left;
}

.elementor-6 .elementor-element.elementor-element-1a4be35>.elementor-widget-container {
    margin: 0px 0px 0px -10px;
}

.elementor-6 .elementor-element.elementor-element-7f8a58b>.elementor-container {
    max-width: 1240px;
}

.elementor-6 .elementor-element.elementor-element-7f8a58b:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-7f8a58b>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../assests/images/05/Blog_bigcircles.svg");
    background-position: 100% 12em;
    background-repeat: no-repeat;
    background-size: auto;
}

.elementor-6 .elementor-element.elementor-element-7f8a58b {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-6 .elementor-element.elementor-element-7f8a58b>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-75dfc0e>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 40px;
}

.elementor-6 .elementor-element.elementor-element-75dfc0e>.elementor-element-populated {
    padding: 100px 0px 100px 0px;
}

.elementor-6 .elementor-element.elementor-element-6b4e764 {
    width: auto;
    max-width: auto;
    top: -130px;
    z-index: 0;
}

body:not(.rtl) .elementor-6 .elementor-element.elementor-element-6b4e764 {
    left: -20px;
}

body.rtl .elementor-6 .elementor-element.elementor-element-6b4e764 {
    right: -20px;
}

.elementor-6 .elementor-element.elementor-element-8c69330 .elementor-heading-title {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 52px;
    font-weight: 700;
    line-height: 1.2em;
}

.elementor-6 .elementor-element.elementor-element-8c69330>.elementor-widget-container {
    margin: 0px 0px 0px 12px;
    background-image: url("../../assests/images/05/blog_headBG.svg");
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-posts-container .elementor-post__thumbnail {
    padding-bottom: calc(0.55 * 100%);
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9:after {
    content: "0.55";
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__thumbnail__link {
    width: 100%;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__meta-data span+span:before {
    content: "///";
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 {
    --grid-column-gap: 0px;
    --grid-row-gap: 0px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post {
    border-radius: 20px;
    padding: 10px 10px 10px 10px;
    background-color: var(--e-global-color-ecb3566);
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__text {
    padding: 10px 10px 10px 10px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__thumbnail {
    border-radius: 12px 12px 12px 12px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9.elementor-posts--thumbnail-left .elementor-post__thumbnail__link {
    margin-right: 15px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9.elementor-posts--thumbnail-right .elementor-post__thumbnail__link {
    margin-left: 15px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9.elementor-posts--thumbnail-top .elementor-post__thumbnail__link {
    margin-bottom: 15px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title,
.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title a {
    color: var(--e-global-color-859d4d9);
    font-family: "Fira Sans", Sans;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.4em;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title {
    margin-bottom: 10px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__meta-data {
    color: #1D2A3E3D;
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__excerpt p {
    color: var(--e-global-color-d68e133);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__excerpt {
    margin-bottom: 10px;
}

.elementor-6 .elementor-element.elementor-element-5a1338a>.elementor-container {
    max-width: 830px;
}

.elementor-6 .elementor-element.elementor-element-5a1338a:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-5a1338a>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: transparent;
    background-image: linear-gradient(120deg, var(--e-global-color-primary) 0%, var(--e-global-color-859d4d9) 100%);
}

.elementor-6 .elementor-element.elementor-element-5a1338a {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 50px 0px 10px 0px;
}

.elementor-6 .elementor-element.elementor-element-5a1338a>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-410937f.elementor-column>.elementor-widget-wrap {
    justify-content: center;
}

.elementor-6 .elementor-element.elementor-element-410937f>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 28px;
}

.elementor-6 .elementor-element.elementor-element-410937f:not(.elementor-motion-effects-element-type-background)>.elementor-widget-wrap,
.elementor-6 .elementor-element.elementor-element-410937f>.elementor-widget-wrap>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../assests/images/05/small-circlesBG.png");
    background-position: top center;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-410937f>.elementor-element-populated {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 50px 0px 30px 0px;
}

.elementor-6 .elementor-element.elementor-element-410937f>.elementor-element-populated>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-6 .elementor-element.elementor-element-aea0b37 {
    text-align: center;
}

.elementor-6 .elementor-element.elementor-element-aea0b37 .elementor-heading-title {
    color: var(--e-global-color-29f88a8);
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 700;
}

.elementor-6 .elementor-element.elementor-element-68a137e {
    text-align: center;
    color: var(--e-global-color-7a318bb);
    font-family: "Fira Sans", Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-6 .elementor-element.elementor-element-de03694 .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-6 .elementor-element.elementor-element-de03694 .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-6 .elementor-element.elementor-element-de03694 .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px 6px 6px 6px;
    padding: 26px 37px 26px 37px;
}

.elementor-6 .elementor-element.elementor-element-de03694>.elementor-widget-container {
    margin: -10px 0px 0px 0px;
    padding: 0px 170px 50px 170px;
    background-image: url("../../assests/images/05/round_arrow.svg");
    background-position: bottom right;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-de03694 {
    width: auto;
    max-width: auto;
}

.elementor-6 .elementor-element.elementor-element-6575e8d:not(.elementor-motion-effects-element-type-background),
.elementor-6 .elementor-element.elementor-element-6575e8d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-image: url("../../assests/images/05/map_circles.svg");
    background-position: 100px 0px;
    background-repeat: no-repeat;
}

.elementor-6 .elementor-element.elementor-element-6575e8d {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 60px 0px 60px 0px;
}

.elementor-6 .elementor-element.elementor-element-6575e8d>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

@media(max-width:1024px) {
    .elementor-6 .elementor-element.elementor-element-247439f {
        padding: 30px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-bdcc506 {
        padding: 20px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-6afece6>.elementor-element-populated {
        margin: 4% 4% 4% 4%;
        --e-column-margin-right: 4%;
        --e-column-margin-left: 4%;
    }

    .elementor-6 .elementor-element.elementor-element-9da2591 .elementor-heading-title {
        font-size: 30px;
    }

    .elementor-6 .elementor-element.elementor-element-618a378 .elementor-heading-title {
        font-size: 16px;
    }

    .elementor-6 .elementor-element.elementor-element-0b0218a>.elementor-widget-container {
        background-position: 200px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-01eeb42>.elementor-widget-container {
        background-position: bottom right;
        background-size: 100px auto;
    }

    .elementor-bc-flex-widget .elementor-6 .elementor-element.elementor-element-61cb816.elementor-column .elementor-widget-wrap {
        align-items: center;
    }

    .elementor-6 .elementor-element.elementor-element-61cb816.elementor-column.elementor-element[data-element_type="column"]>.elementor-widget-wrap.elementor-element-populated {
        align-content: center;
        align-items: center;
    }

    .elementor-6 .elementor-element.elementor-element-896c3c6 {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 20px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-c7bfe75:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-c7bfe75>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: 0px 0vh;
    }

    .elementor-6 .elementor-element.elementor-element-b20a35f .elementor-heading-title {
        font-size: 30px;
        line-height: 1.2em;
    }

    .elementor-6 .elementor-element.elementor-element-b20a35f>.elementor-widget-container {
        padding: 15px 30px 15px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-9b13b74 {
        padding: 30px 30px 30px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-a998abd .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-9821482>.elementor-container {
        max-width: 480px;
    }

    .elementor-6 .elementor-element.elementor-element-9821482 {
        padding: 0px 40px 40px 40px;
    }

    .elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-element-populated {
        margin: 20px 20px 20px 20px;
        --e-column-margin-right: 20px;
        --e-column-margin-left: 20px;
    }

    .elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-element-populated {
        margin: 30px 20px 30px 20px;
        --e-column-margin-right: 20px;
        --e-column-margin-left: 20px;
    }

    .elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-element-populated {
        margin: 20px 20px 20px 20px;
        --e-column-margin-right: 20px;
        --e-column-margin-left: 20px;
    }

    .elementor-6 .elementor-element.elementor-element-5bb7bb0e {
        padding: 30px 30px 30px 30px;
    }

    body:not(.rtl) .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
        right: 0vh;
    }

    body.rtl .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
        left: 0vh;
    }

    .elementor-6 .elementor-element.elementor-element-36374419 {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .elementor-6 .elementor-element.elementor-element-3d94a2e2>.elementor-element-populated {
        margin: 20px 0px 20px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-6 .elementor-element.elementor-element-b7c3ce3 {
        padding: 30px 30px 30px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-ac290af>.elementor-element-populated {
        padding: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-c3b5fab {
        padding: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-6b5b225>.elementor-element-populated {
        padding: 20px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-7008fbb .elementor-heading-title {
        font-size: 30px;
    }

    .elementor-6 .elementor-element.elementor-element-551e68a {
        --e-image-carousel-slides-to-show: 2;
    }

    .elementor-6 .elementor-element.elementor-element-82fc35f {
        padding: 30px 30px 30px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-210e459 img {
        max-width: 90%;
    }

    .elementor-6 .elementor-element.elementor-element-b4e4246>.elementor-element-populated {
        padding: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-5a8e6b7 .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-7fc73d4e {
        padding: 30px 0px 30px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-3e673d69 {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .elementor-6 .elementor-element.elementor-element-3e673d69>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-tab-content {
        padding: 0px 50px 20px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-3c4db939 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }

    .elementor-6 .elementor-element.elementor-element-3c4db939>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-7f8a58b:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-7f8a58b>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: bottom right;
    }

    .elementor-6 .elementor-element.elementor-element-75dfc0e>.elementor-element-populated {
        padding: 30px 30px 30px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title,
    .elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title a {
        font-size: 16px;
    }

    .elementor-6 .elementor-element.elementor-element-5a1338a {
        padding: 30px 30px 30px 30px;
    }

    .elementor-6 .elementor-element.elementor-element-de03694>.elementor-widget-container {
        margin: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-6575e8d:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-6575e8d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: 0px 0px;
    }
}

@media(max-width:767px) {

    .elementor-6 .elementor-element.elementor-element-247439f:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-247439f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: -200em 0px;
    }

    .elementor-6 .elementor-element.elementor-element-247439f {
        padding: 30px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-bdcc506 {
        padding: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-6afece6>.elementor-element-populated {
        margin: 0% 0% 0% 0%;
        --e-column-margin-right: 0%;
        --e-column-margin-left: 0%;
    }

    .elementor-6 .elementor-element.elementor-element-9da2591 {
        text-align: center;
    }

    .elementor-6 .elementor-element.elementor-element-9da2591 .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-9da2591>.elementor-widget-container {
        margin: 0px 20px 0px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-618a378 {
        text-align: center;
    }

    .elementor-6 .elementor-element.elementor-element-618a378 .elementor-heading-title {
        font-size: 14px;
    }

    .elementor-6 .elementor-element.elementor-element-618a378>.elementor-widget-container {
        margin: 0px 40px 0px 40px;
    }

    .elementor-6 .elementor-element.elementor-element-1864738 {
        text-align: center;
        font-size: 16px;
    }

    .elementor-6 .elementor-element.elementor-element-1864738>.elementor-widget-container {
        margin: 0% 5% 0% 5%;
    }

    .elementor-6 .elementor-element.elementor-element-0b0218a>.elementor-widget-container {
        padding: 10px 10px 40px 0px;
        background-position: 250px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-0b0218a {
        width: 100%;
        max-width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-01eeb42>.elementor-widget-container {
        padding: 10px 10px 90px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-d8c8e57>.elementor-widget-container {
        margin: 40px 0px 30px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-3a311fd>.elementor-widget-container {
        margin: 0px 0px 0px -10px;
    }

    .elementor-6 .elementor-element.elementor-element-896c3c6 {
        margin-top: -100px;
        margin-bottom: 0px;
        padding: 30px 10px 40px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-1dfde13>.elementor-widget-container {
        margin: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-c7bfe75:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-c7bfe75>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: bottom right;
        background-size: 0px auto;
    }

    .elementor-6 .elementor-element.elementor-element-b20a35f .elementor-heading-title {
        font-size: 22px;
    }

    .elementor-6 .elementor-element.elementor-element-b20a35f>.elementor-widget-container {
        padding: 20px 30px 10px 30px;
        background-image: url("../../assests/images/05/voiceHBG.svg");
        background-position: top center;
    }

    .elementor-6 .elementor-element.elementor-element-bf87250 .elementor-icon {
        font-size: 30px;
    }

    .elementor-6 .elementor-element.elementor-element-bf87250 {
        width: auto;
        max-width: auto;
        top: 195px;
    }

    body:not(.rtl) .elementor-6 .elementor-element.elementor-element-bf87250 {
        right: 30px;
    }

    body.rtl .elementor-6 .elementor-element.elementor-element-bf87250 {
        left: 30px;
    }

    .elementor-6 .elementor-element.elementor-element-330d8c7 .elementor-heading-title {
        font-size: 14px;
    }

    .elementor-6 .elementor-element.elementor-element-9b13b74 {
        padding: 40px 10px 40px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-a998abd .elementor-heading-title {
        font-size: 28px;
    }

    .elementor-6 .elementor-element.elementor-element-a998abd>.elementor-widget-container {
        padding: 30px 30px 20px 30px;
        background-size: contain;
    }

    .elementor-6 .elementor-element.elementor-element-9821482 {
        padding: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-e03ffc9>.elementor-element-populated {
        margin: 10px 10px 10px 10px;
        --e-column-margin-right: 10px;
        --e-column-margin-left: 10px;
        padding: 60px 20px 10px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-28592ba>.elementor-widget-container {
        padding: 10px 0px 40px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-b6acfd4>.elementor-element-populated {
        margin: 10px 10px 10px 10px;
        --e-column-margin-right: 10px;
        --e-column-margin-left: 10px;
        padding: 60px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-9258fca>.elementor-widget-container {
        padding: 10px 0px 40px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-1bc5ca1>.elementor-element-populated {
        margin: 10px 10px 10px 10px;
        --e-column-margin-right: 10px;
        --e-column-margin-left: 10px;
        padding: 60px 20px 0px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-00e7abe>.elementor-widget-container {
        padding: 10px 0px 40px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-5bb7bb0e {
        padding: 0px 0px 30px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-238d1431 .elementor-heading-title {
        font-size: 28px;
    }

    .elementor-6 .elementor-element.elementor-element-238d1431>.elementor-widget-container {
        padding: 30px 0px 30px 00px;
        background-image: url("../../assests/images/05/howHBGM.svg");
    }

    .elementor-6 .elementor-element.elementor-element-7c53c1e5 .elementor-icon {
        font-size: 100px;
    }

    body:not(.rtl) .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
        right: 0vh;
    }

    body.rtl .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
        left: 0vh;
    }

    .elementor-6 .elementor-element.elementor-element-7c53c1e5 {
        top: 20px;
    }

    .elementor-6 .elementor-element.elementor-element-36374419 {
        margin-top: 0px;
        margin-bottom: 30px;
        padding: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-32b4737 {
        --icon-box-icon-margin: 10px;
    }

    .elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-title,
    .elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-title a {
        font-size: 22px;
    }

    .elementor-6 .elementor-element.elementor-element-32b4737 .elementor-icon-box-description {
        font-size: 14px;
    }

    .elementor-6 .elementor-element.elementor-element-32b4737>.elementor-widget-container {
        padding: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-335c6518 {
        --icon-box-icon-margin: 10px;
    }

    .elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-title,
    .elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-title a {
        font-size: 22px;
    }

    .elementor-6 .elementor-element.elementor-element-335c6518 .elementor-icon-box-description {
        font-size: 14px;
    }

    .elementor-6 .elementor-element.elementor-element-335c6518>.elementor-widget-container {
        margin: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-14dbe19e {
        --icon-box-icon-margin: 10px;
    }

    .elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-title,
    .elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-title a {
        font-size: 22px;
    }

    .elementor-6 .elementor-element.elementor-element-14dbe19e .elementor-icon-box-description {
        font-size: 14px;
    }

    .elementor-6 .elementor-element.elementor-element-14dbe19e>.elementor-widget-container {
        margin: 0px 0px 0px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-b7c3ce3 {
        padding: 40px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-c3b5fab {
        padding: 20px 0px 20px 0px;
    }

    .elementor-6 .elementor-element.elementor-element-6b5b225>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-7008fbb .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-7008fbb>.elementor-widget-container {
        padding: 20px 30px 30px 30px;
        background-position: top center;
    }

    .elementor-6 .elementor-element.elementor-element-551e68a {
        --e-image-carousel-slides-to-show: 1;
    }

    .elementor-6 .elementor-element.elementor-element-82fc35f:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-82fc35f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: bottom left;
    }

    .elementor-6 .elementor-element.elementor-element-82fc35f {
        padding: 40px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-3608abd {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }

    .elementor-6 .elementor-element.elementor-element-5a8e6b7 {
        text-align: center;
    }

    .elementor-6 .elementor-element.elementor-element-5a8e6b7 .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-ecbc93d {
        text-align: center;
    }

    .elementor-6 .elementor-element.elementor-element-3e673d69>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-5a0a5cc3 .elementor-toggle-title {
        line-height: 1.4em;
    }

    .elementor-6 .elementor-element.elementor-element-3c4db939>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-4b7c528a {
        text-align: center;
    }

    .elementor-6 .elementor-element.elementor-element-4b7c528a .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-4b7c528a>.elementor-widget-container {
        margin: 10px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-b2c8d09>.elementor-widget-container {
        margin: 0px 10px 10px 10px;
    }

    .elementor-6 .elementor-element.elementor-element-7f8a58b:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-7f8a58b>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: 100% 20em;
    }

    .elementor-6 .elementor-element.elementor-element-8c69330 .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-8c69330>.elementor-widget-container {
        padding: 50px 0px 30px 0px;
        background-image: url("../../assests/images/05/blogHdBGM.svg");
        background-position: -20px -50px;
        background-repeat: no-repeat;
    }

    .elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-posts-container .elementor-post__thumbnail {
        padding-bottom: calc(0.5 * 100%);
    }

    .elementor-6 .elementor-element.elementor-element-d7c3cd9:after {
        content: "0.5";
    }

    .elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__thumbnail__link {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title,
    .elementor-6 .elementor-element.elementor-element-d7c3cd9 .elementor-post__title a {
        font-size: 20px;
    }

    .elementor-6 .elementor-element.elementor-element-5a1338a {
        padding: 20px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-aea0b37 .elementor-heading-title {
        font-size: 32px;
    }

    .elementor-6 .elementor-element.elementor-element-de03694 .elementor-button {
        padding: 20px 20px 20px 20px;
    }

    .elementor-6 .elementor-element.elementor-element-de03694>.elementor-widget-container {
        padding: 10px 20px 80px 20px;
        background-position: 170px 40px;
    }

    .elementor-6 .elementor-element.elementor-element-6575e8d:not(.elementor-motion-effects-element-type-background),
    .elementor-6 .elementor-element.elementor-element-6575e8d>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-position: 0px 0px;
    }
}

@media(min-width:768px) {
    .elementor-6 .elementor-element.elementor-element-6afece6 {
        width: 40%;
    }

    .elementor-6 .elementor-element.elementor-element-61cb816 {
        width: 60%;
    }

    .elementor-6 .elementor-element.elementor-element-ab7383b {
        width: 31%;
    }

    .elementor-6 .elementor-element.elementor-element-21a3245 {
        width: 36%;
    }

    .elementor-6 .elementor-element.elementor-element-a0a8d90 {
        width: 32.333%;
    }

    .elementor-6 .elementor-element.elementor-element-55227fef {
        width: 35%;
    }

    .elementor-6 .elementor-element.elementor-element-3d94a2e2 {
        width: 39%;
    }

    .elementor-6 .elementor-element.elementor-element-265771a {
        width: 25.666%;
    }

    .elementor-6 .elementor-element.elementor-element-3608abd {
        width: 48%;
    }

    .elementor-6 .elementor-element.elementor-element-b4e4246 {
        width: 52%;
    }

    .elementor-6 .elementor-element.elementor-element-3e673d69 {
        width: 53%;
    }

    .elementor-6 .elementor-element.elementor-element-3c4db939 {
        width: 47%;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-6 .elementor-element.elementor-element-6afece6 {
        width: 50%;
    }

    .elementor-6 .elementor-element.elementor-element-61cb816 {
        width: 50%;
    }

    .elementor-6 .elementor-element.elementor-element-e03ffc9 {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-b6acfd4 {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-1bc5ca1 {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-55227fef {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-3d94a2e2 {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-265771a {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-3e673d69 {
        width: 100%;
    }

    .elementor-6 .elementor-element.elementor-element-3c4db939 {
        width: 100%;
    }
}

/* Start custom CSS */
@media(max-width:767px) {
    .heroDesc ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .heroDesc ul li {
        padding: 0;
        margin: 0;
    }
    .elementor-6 .elementor-element.elementor-element-0b0218a .elementor-button {
        font-family: "Fira Sans", Sans;
        font-size: 18px;
        font-weight: 500;
        border-radius: 5px 5px 5px 5px;
        padding: 15px 25px 15px 20px;
        width: 300px;
    }
}

/* End custom CSS */