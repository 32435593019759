.card-box{
    padding: 30px;
background-color: white;
height: auto;
max-height: 250px;
text-align: center;
font-family: "Fira Sans", Sans;
margin-bottom: 10px;
}
.heading-cable{
    font-family: "Fira Sans", Sans;
    font-weight: 700;
}
.imgMap{
    display: flex;
    justify-content: center;
    text-align: center;
}
.innerimg-card{
    height: 70px !important;
    margin-bottom: 10px ;
}
.map-img{
   

    height: 350px !important ;
}
.cardMainHeading{
    text-align: center;
    padding-bottom: 10px;
    text-transform: capitalize;
    font-family: "Fira Sans", Sans;
    font-weight: 700;
}
.cardMainHeadingother{
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: capitalize;
    font-family: "Fira Sans", Sans;
    font-weight: 700;

}
.cardMaindiv{
    background-color:#f3f5f8;
    padding:60px;
}
@media only screen and (max-width: 600px) {
    .map-img{
   

        height: 100% !important ;
    }
    .cardMaindiv{
        background-color:#f3f5f8;
        padding:40px;
    }
    .card-box{
        padding: 30px;
    background-color: white;
    height: auto;
    max-height: 300px;
    text-align: center;
    margin-bottom: 10px;
    }
}