.elementor-37142 .elementor-element.elementor-element-fee5260:not(.elementor-motion-effects-element-type-background),
.elementor-37142 .elementor-element.elementor-element-fee5260>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color:  var(--e-global-color-ac969e5);   
    background-image: linear-gradient(120deg, var(--e-global-color-ac969e5) 0%, var(--e-global-color-ac969e5) 100%);
}

.elementor-37142 .elementor-element.elementor-element-fee5260 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 60px 0px 40px 0px;
}

.elementor-37142 .elementor-element.elementor-element-fee5260>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1:not(.elementor-motion-effects-element-type-background),
.elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-29f88a8);
    background-image: url("../../assests/images/05/circles.svg");
    background-position: center left;
    background-repeat: no-repeat;
    background-size: auto;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1,
.elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-background-overlay {
    border-radius: 40px 40px 40px 40px;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1 a {
    color: #69D58B;
}

.elementor-37142 .elementor-element.elementor-element-a4458e1 a:hover {
    color: var(--e-global-color-accent);
}

.elementor-37142 .elementor-element.elementor-element-4382977 .elementor-heading-title {
    font-family: "Fira Sans", Sans;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2em;
}

.elementor-37142 .elementor-element.elementor-element-4382977>.elementor-widget-container {
    margin: 0px 0px 0px 10px;
    padding: 10px 0px 10px 50px;
    background-image: url("../../assests/images/05/ctaHBG.svg");
    background-position: 20px 30px;
    background-repeat: no-repeat;
}

.elementor-37142 .elementor-element.elementor-element-c757507.elementor-column>.elementor-widget-wrap {
    justify-content: center;
}

.elementor-37142 .elementor-element.elementor-element-c5530c8 .elementor-heading-title {
    color: #69D58B;
    font-family: "Fira Sans", Sans;
    font-size: 38px;
    font-weight: 600;
    text-decoration: none;
    line-height: 1.2em;
}

.elementor-37142 .elementor-element.elementor-element-c5530c8>.elementor-widget-container {
    padding: 0px 30px 0px 30px;
}

.elementor-37142 .elementor-element.elementor-element-c5530c8 {
    width: auto;
    max-width: auto;
}

.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
}

.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button .elementor-align-icon-left {
    margin-right: 10px;
}

.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button {
    font-family: "Fira Sans", Sans;
    font-size: 18px;
    font-weight: 500;
    fill: var(--e-global-color-29f88a8);
    color: var(--e-global-color-29f88a8);
    border-radius: 6px 6px 6px 6px;
    padding: 25px 40px 25px 40px;
}

.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button:hover,
.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button:focus {
    color: var(--e-global-color-29f88a8);
}

.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button:hover svg,
.elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button:focus svg {
    fill: var(--e-global-color-29f88a8);
}

.elementor-37142 .elementor-element.elementor-element-de6bb48 {
    width: auto;
    max-width: auto;
}

.elementor-37142 .elementor-element.elementor-element-63f5d3c {
   color: var(--e-global-color-7a318bb);
    /* color: #FFFFFF4D; */
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5em;
}

.elementor-37142 .elementor-element.elementor-element-63f5d3c>.elementor-widget-container {
    margin: 30px 15px 0px 15px;
}

.elementor-37142 .elementor-element.elementor-element-6c11357:not(.elementor-motion-effects-element-type-background),
.elementor-37142 .elementor-element.elementor-element-6c11357>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: var(--e-global-color-859d4d9);
}

.elementor-37142 .elementor-element.elementor-element-6c11357 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 40px 0px 0px 0px;
}

.elementor-37142 .elementor-element.elementor-element-6c11357>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-37142 .elementor-element.elementor-element-6c11357 a {
    color: var(--e-global-color-7a318bb);
}

.elementor-37142 .elementor-element.elementor-element-6c11357 a:hover {
    color: var(--e-global-color-29f88a8);
}

.elementor-37142 .elementor-element.elementor-element-5d4d941 {
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
}

.elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-post__title,
.elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-post__title a {
    color: var(--e-global-color-29f88a8);
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4em;
}

.elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-post__title {
    margin-bottom: 0px;
}

.elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-pagination {
    text-align: center;
    margin-top: 50px;
}

.elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-pagination .page-numbers:not(.dots) {
    color: var(--e-global-color-accent);
}

body:not(.rtl) .elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-pagination .page-numbers:not(:first-child) {
    margin-left: calc(10px/2);
}

body:not(.rtl) .elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-pagination .page-numbers:not(:last-child) {
    margin-right: calc(10px/2);
}

body.rtl .elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-pagination .page-numbers:not(:first-child) {
    margin-right: calc(10px/2);
}

body.rtl .elementor-37142 .elementor-element.elementor-element-5d4d941 .elementor-pagination .page-numbers:not(:last-child) {
    margin-left: calc(10px/2);
}

.elementor-37142 .elementor-element.elementor-element-ce5e59e .elementor-button {
    background-color: transparent;
    background-image: linear-gradient(180deg, var(--e-global-color-primary) 0%, var(--e-global-color-primary) 100%);
    border-radius: 6px 6px 6px 6px;
}

.elementor-37142 .elementor-element.elementor-element-ce5e59e .elementor-button:hover,
.elementor-37142 .elementor-element.elementor-element-ce5e59e .elementor-button:focus {
    background-color: transparent;
    background-image: linear-gradient(180deg, var(--e-global-color-accent) 0%, var(--e-global-color-accent) 100%);
}

.elementor-37142 .elementor-element.elementor-element-ce5e59e>.elementor-widget-container {
    margin: 20px 0px 20px 0px;
}

.elementor-37142 .elementor-element.elementor-element-aa90d0f>.elementor-container>.elementor-column>.elementor-widget-wrap {
    align-content: center;
    align-items: center;
}

.elementor-37142 .elementor-element.elementor-element-aa90d0f:not(.elementor-motion-effects-element-type-background),
.elementor-37142 .elementor-element.elementor-element-aa90d0f>.elementor-motion-effects-container>.elementor-motion-effects-layer {
    background-color: darkgreen;
}

.elementor-37142 .elementor-element.elementor-element-aa90d0f {
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: var(--e-global-color-29f88a8);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 15px 0px 15px 0px;
}

.elementor-37142 .elementor-element.elementor-element-aa90d0f>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-37142 .elementor-element.elementor-element-aa90d0f a {
    color: var(--e-global-color-29f88a8);
}

.elementor-37142 .elementor-element.elementor-element-aa90d0f a:hover {
    color: var(--e-global-color-accent);
}

.elementor-37142 .elementor-element.elementor-element-a4ab7e3 {
    text-align: left;
}

.elementor-37142 .elementor-element.elementor-element-a4ab7e3 img {
    width: 200px;
}

.elementor-37142 .elementor-element.elementor-element-3825041 img {
    max-width: 90px;
}

.elementor-37142 .elementor-element.elementor-element-3825041>.elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 5px 5px 10px 5px;
    background-color: var(--e-global-color-29f88a8);
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-color: var(--e-global-color-ecb3566);
}

.elementor-37142 .elementor-element.elementor-element-3825041 {
    width: auto;
    max-width: auto;
    bottom: 35vh;
    z-index: 99999;
}

body:not(.rtl) .elementor-37142 .elementor-element.elementor-element-3825041 {
    left: 0px;
}

body.rtl .elementor-37142 .elementor-element.elementor-element-3825041 {
    right: 0px;
}

.elementor-37142 .elementor-element.elementor-element-3024b4a {
    text-align: center;
}

.elementor-37142 .elementor-element.elementor-element-3024b4a .elementor-heading-title {
    color: var(--e-global-color-29f88a8);
    font-family: "Fira Sans", Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6em;
}

.elementor-37142 .elementor-element.elementor-element-2ae9d54 {
    text-align: right;
}

.elementor-37142 .elementor-element.elementor-element-2ae9d54 .elementor-heading-title {
    color: var(--e-global-color-29f88a8);
    font-family: var(--e-global-typography-text-font-family), Sans;
    font-size: var(--e-global-typography-text-font-size);
    font-weight: var(--e-global-typography-text-font-weight);
    line-height: var(--e-global-typography-text-line-height);
}

@media(max-width:1024px) {
    .elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-container {
        max-width: 600px;
    }

    .elementor-37142 .elementor-element.elementor-element-a4458e1,
    .elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-background-overlay {
        border-radius: 20px 20px 20px 20px;
    }

    .elementor-37142 .elementor-element.elementor-element-4382977 .elementor-heading-title {
        font-size: 20px;
    }

    .elementor-37142 .elementor-element.elementor-element-4382977>.elementor-widget-container {
        padding: 10px 10px 10px 10px;
        background-image: url("../../assests/images/05/ctaBG.svg");
        background-position: center center;
    }

    .elementor-37142 .elementor-element.elementor-element-c5530c8 .elementor-heading-title {
        font-size: 16px;
    }

    .elementor-37142 .elementor-element.elementor-element-c5530c8>.elementor-widget-container {
        margin: 10px 0px 10px 0px;
        padding: 0px 10px 0px 10px;
    }

    .elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button {
        font-size: 14px;
        padding: 10px 25px 10px 25px;
    }

    .elementor-37142 .elementor-element.elementor-element-3825041 img {
        max-width: 75px;
    }

    .elementor-37142 .elementor-element.elementor-element-2ae9d54 .elementor-heading-title {
        font-size: var(--e-global-typography-text-font-size);
        line-height: var(--e-global-typography-text-line-height);
    }
}

@media(max-width:767px) {
    .elementor-37142 .elementor-element.elementor-element-fee5260 {
        padding: 60px 15px 60px 15px;
    }

    .elementor-37142 .elementor-element.elementor-element-5411ae1>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
        margin-bottom: 0px;
    }

    .elementor-37142 .elementor-element.elementor-element-5411ae1>.elementor-element-populated {
        padding: 0px 0px 0px 0px;
    }

    .elementor-37142 .elementor-element.elementor-element-a4458e1:not(.elementor-motion-effects-element-type-background),
    .elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-motion-effects-container>.elementor-motion-effects-layer {
        background-image: url("../../assests/images/05/circles.svg");
        background-position: top left;
        background-size: contain;
    }

    .elementor-37142 .elementor-element.elementor-element-a4458e1,
    .elementor-37142 .elementor-element.elementor-element-a4458e1>.elementor-background-overlay {
        border-radius: 12px 12px 12px 12px;
    }

    .elementor-37142 .elementor-element.elementor-element-5dd6475>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
        margin-bottom: 0px;
    }

    .elementor-37142 .elementor-element.elementor-element-5dd6475>.elementor-element-populated {
        padding: 10px 10px 10px 10px;
    }

    .elementor-37142 .elementor-element.elementor-element-4382977 {
        text-align: center;
    }

    .elementor-37142 .elementor-element.elementor-element-4382977 .elementor-heading-title {
        font-size: 14px;
    }

    .elementor-37142 .elementor-element.elementor-element-4382977>.elementor-widget-container {
        margin: 0px 10px 0px 10px;
        padding: 0px 0px 0px 0px;
        background-image: url("../../assests/images/05/ctaBG.svg");
        background-position: 0px 15px;
        background-size: contain;
    }

    .elementor-37142 .elementor-element.elementor-element-c757507>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
        margin-bottom: 0px;
    }

    .elementor-37142 .elementor-element.elementor-element-c757507>.elementor-element-populated {
        padding: 0px 15px 10px 15px;
    }

    .elementor-37142 .elementor-element.elementor-element-c5530c8 {
        text-align: center;
        width: 100%;
        max-width: 100%;
    }

    .elementor-37142 .elementor-element.elementor-element-c5530c8 .elementor-heading-title {
        font-size: 18px;
    }

    .elementor-37142 .elementor-element.elementor-element-c5530c8>.elementor-widget-container {
        margin: 0px 30px 5px 30px;
    }

    .elementor-37142 .elementor-element.elementor-element-de6bb48 .elementor-button {
        font-size: 16px;
        padding: 10px 10px 10px 10px;
    }

    .elementor-37142 .elementor-element.elementor-element-de6bb48>.elementor-widget-container {
        margin: 0px 0px 0px 0px;
    }

    .elementor-37142 .elementor-element.elementor-element-de6bb48 {
        width: 100%;
        max-width: 100%;
    }

    .elementor-37142 .elementor-element.elementor-element-63f5d3c>.elementor-widget-container {
        margin: 30px 0px 10px 0px;
    }

    .elementor-37142 .elementor-element.elementor-element-5d4d941>.elementor-widget-container {
        margin: 10px 10px 10px 10px;
    }

    .elementor-37142 .elementor-element.elementor-element-a4ab7e3 {
        text-align: center;
    }

    .elementor-37142 .elementor-element.elementor-element-a4ab7e3 img {
        width: 280px;
    }

    .elementor-37142 .elementor-element.elementor-element-3825041 img {
        max-width: 50px;
    }

    .elementor-37142 .elementor-element.elementor-element-3024b4a>.elementor-widget-container {
        margin: 0px 10px 0px 10px;
    }

    .elementor-37142 .elementor-element.elementor-element-2ae9d54 {
        text-align: center;
    }

    .elementor-37142 .elementor-element.elementor-element-2ae9d54 .elementor-heading-title {
        font-size: var(--e-global-typography-text-font-size);
        line-height: var(--e-global-typography-text-line-height);
    }
}

@media(min-width:768px) {
    .elementor-37142 .elementor-element.elementor-element-5dd6475 {
        width: 45%;
    }

    .elementor-37142 .elementor-element.elementor-element-c757507 {
        width: 55%;
    }

    .elementor-37142 .elementor-element.elementor-element-2ec072d {
        width: 30%;
    }

    .elementor-37142 .elementor-element.elementor-element-826ac7b {
        width: 39.333%;
    }

    .elementor-37142 .elementor-element.elementor-element-46f3f25 {
        width: 30%;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-37142 .elementor-element.elementor-element-5dd6475 {
        width: 50%;
    }

    .elementor-37142 .elementor-element.elementor-element-c757507 {
        width: 50%;
    }
}

/* Start custom CSS for section, class: .elementor-element-6c11357 */
.locationsSitemap .elementor-post__title a:hover {
    color: var(--e-global-color-accent) !important;
}

.locationsSitemap .elementor-post__title:before {
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    color: var(--e-global-color-accent);
    font-size: 14px;
    position: absolute;
    left: 0;
}

.locationsSitemap .elementor-post__title {
    position: relative;
    padding-left: 30px;
}

/* End custom CSS */