.elementor-kit-1771 {
    --e-global-color-primary: #2F3E57;
    --e-global-color-secondary: #F99357;
    --e-global-color-text: #2F3E57;
    --e-global-color-accent: #47A164;
    --e-global-color-ecb3566: #EEEFF2;
    --e-global-color-859d4d9: #1D2A3E;
    --e-global-color-29f88a8: #FFFFFF;
    --e-global-color-7a318bb: #FFFFFFB3;
    --e-global-color-d68e133: #2F3E57B3;
    --e-global-color-ac969e5: #6CD88D;
    --e-global-color-986b743: #26354B;
    --e-global-typography-primary-font-family: "Fira Sans";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Fira Sans";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Fira Sans";
    --e-global-typography-text-font-size: 16px;
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-text-line-height: 1.6em;
    --e-global-typography-accent-font-family: "Fira Sans";
    --e-global-typography-accent-font-weight: 500;
    color: var(--e-global-color-text);
}

.elementor-kit-1771 a {
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
}

.elementor-kit-1771 button,
.elementor-kit-1771 input[type="button"],
.elementor-kit-1771 input[type="submit"],
.elementor-kit-1771 .elementor-button {
    text-decoration: none;
    color: var(--e-global-color-29f88a8);
    background-color: transparent;
    background-image: linear-gradient(223deg, #F99357 0%, #E76F2E 100%);
}

.elementor-kit-1771 button:hover,
.elementor-kit-1771 button:focus,
.elementor-kit-1771 input[type="button"]:hover,
.elementor-kit-1771 input[type="button"]:focus,
.elementor-kit-1771 input[type="submit"]:hover,
.elementor-kit-1771 input[type="submit"]:focus,
.elementor-kit-1771 .elementor-button:hover,
.elementor-kit-1771 .elementor-button:focus {
    color: var(--e-global-color-29f88a8);
    background-color: transparent;
    background-image: linear-gradient(223deg, #E76F2E 0%, #F99357 100%);
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1260px;
}

.e-container {
    --container-max-width: 1260px;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.elementor-element {
    --widgets-spacing: 20px;
}



h1.entry-title {
    display: var(--page-title-display);
}

@media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px;
    }

    .e-container {
        --container-max-width: 1024px;
    }
}

@media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px;
    }

    .e-container {
        --container-max-width: 767px;
    }
}