.elementor-widget-heading .elementor-heading-title {
	color: var(--e-global-color-primary);
	font-family:var(--e-global-typography-text-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
	
}

.elementor-widget-image .widget-image-caption {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-text-editor {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
	background-color: var(--e-global-color-primary);
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap,
.elementor-widget-text-editor.elementor-drop-cap-view-default .elementor-drop-cap {
	color: var(--e-global-color-primary);
	border-color: var(--e-global-color-primary);
}

.elementor-widget-button .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-divider {
	--divider-color: var(--e-global-color-secondary);
}

.elementor-widget-divider .elementor-divider__text {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-divider.elementor-view-stacked .elementor-icon {
	background-color: var(--e-global-color-secondary);
}

.elementor-widget-divider.elementor-view-framed .elementor-icon,
.elementor-widget-divider.elementor-view-default .elementor-icon {
	color: var(--e-global-color-secondary);
	border-color: var(--e-global-color-secondary);
}

.elementor-widget-divider.elementor-view-framed .elementor-icon,
.elementor-widget-divider.elementor-view-default .elementor-icon svg {
	fill: var(--e-global-color-secondary);
}

.elementor-widget-image-box .elementor-image-box-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-image-box .elementor-image-box-description {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-icon.elementor-view-stacked .elementor-icon {
	background-color: var(--e-global-color-primary);
}

.elementor-widget-icon.elementor-view-framed .elementor-icon,
.elementor-widget-icon.elementor-view-default .elementor-icon {
	color: var(--e-global-color-primary);
	border-color: var(--e-global-color-primary);
}

.elementor-widget-icon.elementor-view-framed .elementor-icon,
.elementor-widget-icon.elementor-view-default .elementor-icon svg {
	fill: var(--e-global-color-primary);
}

.elementor-widget-icon-box.elementor-view-stacked .elementor-icon {
	background-color: var(--e-global-color-primary);
}

.elementor-widget-icon-box.elementor-view-framed .elementor-icon,
.elementor-widget-icon-box.elementor-view-default .elementor-icon {
	fill: var(--e-global-color-primary);
	color: var(--e-global-color-primary);
	border-color: var(--e-global-color-primary);
}

.elementor-widget-icon-box .elementor-icon-box-title {
	color: var(--e-global-color-primary);
}

.elementor-widget-icon-box .elementor-icon-box-title,
.elementor-widget-icon-box .elementor-icon-box-title a {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-icon-box .elementor-icon-box-description {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-star-rating .elementor-star-rating__title {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-image-gallery .gallery-item .gallery-caption {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-icon-list .elementor-icon-list-item:not(:last-child):after {
	border-color: var(--e-global-color-text);
}

.elementor-widget-icon-list .elementor-icon-list-icon i {
	color: var(--e-global-color-primary);
}

.elementor-widget-icon-list .elementor-icon-list-icon svg {
	fill: var(--e-global-color-primary);
}

.elementor-widget-icon-list .elementor-icon-list-text {
	color: var(--e-global-color-secondary);
}

.elementor-widget-icon-list .elementor-icon-list-item>.elementor-icon-list-text,
.elementor-widget-icon-list .elementor-icon-list-item>a {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-counter .elementor-counter-number-wrapper {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-counter .elementor-counter-title {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-progress .elementor-progress-wrapper .elementor-progress-bar {
	background-color: var(--e-global-color-primary);
}

.elementor-widget-progress .elementor-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-testimonial .elementor-testimonial-content {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-testimonial .elementor-testimonial-name {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-testimonial .elementor-testimonial-job {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-tabs .elementor-tab-title,
.elementor-widget-tabs .elementor-tab-title a {
	color: var(--e-global-color-primary);
}

.elementor-widget-tabs .elementor-tab-title.elementor-active,
.elementor-widget-tabs .elementor-tab-title.elementor-active a {
	color: var(--e-global-color-accent);
}

.elementor-widget-tabs .elementor-tab-title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-tabs .elementor-tab-content {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-accordion .elementor-accordion-icon,
.elementor-widget-accordion .elementor-accordion-title {
	color: var(--e-global-color-primary);
}

.elementor-widget-accordion .elementor-accordion-icon svg {
	fill: var(--e-global-color-primary);
}

.elementor-widget-accordion .elementor-active .elementor-accordion-icon,
.elementor-widget-accordion .elementor-active .elementor-accordion-title {
	color: var(--e-global-color-accent);
}

.elementor-widget-accordion .elementor-active .elementor-accordion-icon svg {
	fill: var(--e-global-color-accent);
}

.elementor-widget-accordion .elementor-accordion-title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-accordion .elementor-tab-content {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-toggle .elementor-toggle-title,
.elementor-widget-toggle .elementor-toggle-icon {
	color: var(--e-global-color-primary);
}

.elementor-widget-toggle .elementor-toggle-icon svg {
	fill: var(--e-global-color-primary);
}

.elementor-widget-toggle .elementor-tab-title.elementor-active a,
.elementor-widget-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon {
	color: var(--e-global-color-accent);
}

.elementor-widget-toggle .elementor-toggle-title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-toggle .elementor-tab-content {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-alert .elementor-alert-title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-alert .elementor-alert-description {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-text-path {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-theme-site-logo .widget-image-caption {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-theme-site-title .elementor-heading-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-theme-page-title .elementor-heading-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-theme-post-title .elementor-heading-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-theme-post-excerpt .elementor-widget-container {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-theme-post-content {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-theme-post-featured-image .widget-image-caption {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-theme-archive-title .elementor-heading-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-archive-posts .elementor-post__title,
.elementor-widget-archive-posts .elementor-post__title a {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-archive-posts .elementor-post__meta-data {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-archive-posts .elementor-post__excerpt p {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-archive-posts .elementor-post__read-more {
	color: var(--e-global-color-accent);
}

.elementor-widget-archive-posts a.elementor-post__read-more {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-archive-posts .elementor-post__card .elementor-post__badge {
	background-color: var(--e-global-color-accent);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-archive-posts .elementor-pagination {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-archive-posts .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-archive-posts .e-load-more-message {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-archive-posts .elementor-posts-nothing-found {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-posts .elementor-post__title,
.elementor-widget-posts .elementor-post__title a {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-posts .elementor-post__meta-data {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-posts .elementor-post__excerpt p {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-posts .elementor-post__read-more {
	color: var(--e-global-color-accent);
}

.elementor-widget-posts a.elementor-post__read-more {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-posts .elementor-post__card .elementor-post__badge {
	background-color: var(--e-global-color-accent);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-posts .elementor-pagination {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-posts .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-posts .e-load-more-message {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-portfolio a .elementor-portfolio-item__overlay {
	background-color: var(--e-global-color-accent);
}

.elementor-widget-portfolio .elementor-portfolio-item__title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-portfolio .elementor-portfolio__filter {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-portfolio .elementor-portfolio__filter.elementor-active {
	color: var(--e-global-color-primary);
}

.elementor-widget-gallery .elementor-gallery-item__title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-gallery .elementor-gallery-item__description {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-gallery {
	--galleries-title-color-normal: var(--e-global-color-primary);
	--galleries-title-color-hover: var(--e-global-color-secondary);
	--galleries-pointer-bg-color-hover: var(--e-global-color-accent);
	--gallery-title-color-active: var(--e-global-color-secondary);
	--galleries-pointer-bg-color-active: var(--e-global-color-accent);
}

.elementor-widget-gallery .elementor-gallery-title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-form .elementor-field-group>label,
.elementor-widget-form .elementor-field-subgroup label {
	color: var(--e-global-color-text);
}

.elementor-widget-form .elementor-field-group>label {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-form .elementor-field-type-html {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-form .elementor-field-group .elementor-field {
	color: var(--e-global-color-text);
}

.elementor-widget-form .elementor-field-group .elementor-field,
.elementor-widget-form .elementor-field-subgroup label {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-form .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-form .e-form__buttons__wrapper__button-next {
	background-color: var(--e-global-color-accent);
}

.elementor-widget-form .elementor-button[type="submit"] {
	background-color: var(--e-global-color-accent);
}

.elementor-widget-form .e-form__buttons__wrapper__button-previous {
	background-color: var(--e-global-color-accent);
}

.elementor-widget-form .elementor-message {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-form .e-form__indicators__indicator,
.elementor-widget-form .e-form__indicators__indicator__label {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-form {
	--e-form-steps-indicator-inactive-primary-color: var(--e-global-color-text);
	--e-form-steps-indicator-active-primary-color: var(--e-global-color-accent);
	--e-form-steps-indicator-completed-primary-color: var(--e-global-color-accent);
	--e-form-steps-indicator-progress-color: var(--e-global-color-accent);
	--e-form-steps-indicator-progress-background-color: var(--e-global-color-text);
	--e-form-steps-indicator-progress-meter-color: var(--e-global-color-text);
}

.elementor-widget-form .e-form__indicators__indicator__progress__meter {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-login .elementor-field-group>a {
	color: var(--e-global-color-text);
}

.elementor-widget-login .elementor-field-group>a:hover {
	color: var(--e-global-color-accent);
}

.elementor-widget-login .elementor-form-fields-wrapper label {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-login .elementor-field-group .elementor-field {
	color: var(--e-global-color-text);
}

.elementor-widget-login .elementor-field-group .elementor-field,
.elementor-widget-login .elementor-field-subgroup label {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-login .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-login .elementor-widget-container .elementor-login__logged-in-message {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-slides .elementor-slide-heading {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-slides .elementor-slide-description {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-slides .elementor-slide-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-nav-menu .elementor-nav-menu .elementor-item {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item {
	color: var(--e-global-color-text);
	fill: var(--e-global-color-text);
}

.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item:hover,
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item.elementor-item-active,
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item.highlighted,
.elementor-widget-nav-menu .elementor-nav-menu--main .elementor-item:focus {
	color: var(--e-global-color-accent);
	fill: var(--e-global-color-accent);
}

.elementor-widget-nav-menu .elementor-nav-menu--main:not(.e--pointer-framed) .elementor-item:before,
.elementor-widget-nav-menu .elementor-nav-menu--main:not(.e--pointer-framed) .elementor-item:after {
	background-color: var(--e-global-color-accent);
}

.elementor-widget-nav-menu .e--pointer-framed .elementor-item:before,
.elementor-widget-nav-menu .e--pointer-framed .elementor-item:after {
	border-color: var(--e-global-color-accent);
}

.elementor-widget-nav-menu {
	--e-nav-menu-divider-color: var(--e-global-color-text);
}

.elementor-widget-nav-menu .elementor-nav-menu--dropdown .elementor-item,
.elementor-widget-nav-menu .elementor-nav-menu--dropdown .elementor-sub-item {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-animated-headline .elementor-headline-dynamic-wrapper path {
	stroke: var(--e-global-color-accent);
}

.elementor-widget-animated-headline .elementor-headline-plain-text {
	color: var(--e-global-color-secondary);
}

.elementor-widget-animated-headline .elementor-headline {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-animated-headline {
	--dynamic-text-color: var(--e-global-color-secondary);
}

.elementor-widget-animated-headline .elementor-headline-dynamic-text {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-hotspot .widget-image-caption {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-hotspot {
	--hotspot-color: var(--e-global-color-primary);
	--hotspot-box-color: var(--e-global-color-secondary);
	--tooltip-color: var(--e-global-color-secondary);
}

.elementor-widget-hotspot .e-hotspot__label {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-hotspot .e-hotspot__tooltip {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-price-list .elementor-price-list-header {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-list .elementor-price-list-price {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-list .elementor-price-list-description {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-price-list .elementor-price-list-separator {
	border-bottom-color: var(--e-global-color-secondary);
}

.elementor-widget-price-table {
	--e-price-table-header-background-color: var(--e-global-color-secondary);
}

.elementor-widget-price-table .elementor-price-table__heading {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__subheading {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-price-table .elementor-price-table .elementor-price-table__price {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__original-price {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__period {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-price-table .elementor-price-table__features-list {
	--e-price-table-features-list-color: var(--e-global-color-text);
}

.elementor-widget-price-table .elementor-price-table__features-list li {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-price-table .elementor-price-table__features-list li:before {
	border-top-color: var(--e-global-color-text);
}

.elementor-widget-price-table .elementor-price-table__button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-price-table .elementor-price-table__additional_info {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-price-table .elementor-price-table__ribbon-inner {
	background-color: var(--e-global-color-accent);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-flip-box .elementor-flip-box__front .elementor-flip-box__layer__title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-flip-box .elementor-flip-box__front .elementor-flip-box__layer__description {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-flip-box .elementor-flip-box__back .elementor-flip-box__layer__title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-flip-box .elementor-flip-box__back .elementor-flip-box__layer__description {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-flip-box .elementor-flip-box__button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-call-to-action .elementor-cta__title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-call-to-action .elementor-cta__description {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-call-to-action .elementor-cta__button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-call-to-action .elementor-ribbon-inner {
	background-color: var(--e-global-color-accent);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-media-carousel .elementor-carousel-image-overlay {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-testimonial-carousel .elementor-testimonial__text {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-testimonial-carousel .elementor-testimonial__name {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-testimonial-carousel .elementor-testimonial__title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-reviews .elementor-testimonial__header,
.elementor-widget-reviews .elementor-testimonial__name {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-reviews .elementor-testimonial__text {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-table-of-contents {
	--header-color: var(--e-global-color-secondary);
	--item-text-color: var(--e-global-color-text);
	--item-text-hover-color: var(--e-global-color-accent);
	--marker-color: var(--e-global-color-text);
}

.elementor-widget-table-of-contents .elementor-toc__header,
.elementor-widget-table-of-contents .elementor-toc__header-title {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-table-of-contents .elementor-toc__list-item {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-countdown .elementor-countdown-item {
	background-color: var(--e-global-color-primary);
}

.elementor-widget-countdown .elementor-countdown-digits {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-countdown .elementor-countdown-label {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-countdown .elementor-countdown-expire--message {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-search-form input[type="search"].elementor-search-form__input {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-search-form .elementor-search-form__input,
.elementor-widget-search-form .elementor-search-form__icon,
.elementor-widget-search-form .elementor-lightbox .dialog-lightbox-close-button,
.elementor-widget-search-form .elementor-lightbox .dialog-lightbox-close-button:hover,
.elementor-widget-search-form.elementor-search-form--skin-full_screen input[type="search"].elementor-search-form__input {
	color: var(--e-global-color-text);
	fill: var(--e-global-color-text);
}

.elementor-widget-search-form .elementor-search-form__submit {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
	background-color: var(--e-global-color-secondary);
}

.elementor-widget-author-box .elementor-author-box__name {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-author-box .elementor-author-box__bio {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-author-box .elementor-author-box__button {
	color: var(--e-global-color-secondary);
	border-color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-author-box .elementor-author-box__button:hover {
	border-color: var(--e-global-color-secondary);
	color: var(--e-global-color-secondary);
}

.elementor-widget-post-navigation span.post-navigation__prev--label {
	color: var(--e-global-color-text);
}

.elementor-widget-post-navigation span.post-navigation__next--label {
	color: var(--e-global-color-text);
}

.elementor-widget-post-navigation span.post-navigation__prev--label,
.elementor-widget-post-navigation span.post-navigation__next--label {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-post-navigation span.post-navigation__prev--title,
.elementor-widget-post-navigation span.post-navigation__next--title {
	color: var(--e-global-color-secondary);
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-post-info .elementor-icon-list-item:not(:last-child):after {
	border-color: var(--e-global-color-text);
}

.elementor-widget-post-info .elementor-icon-list-icon i {
	color: var(--e-global-color-primary);
}

.elementor-widget-post-info .elementor-icon-list-icon svg {
	fill: var(--e-global-color-primary);
}

.elementor-widget-post-info .elementor-icon-list-text,
.elementor-widget-post-info .elementor-icon-list-text a {
	color: var(--e-global-color-secondary);
}

.elementor-widget-post-info .elementor-icon-list-item {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-sitemap .elementor-sitemap-title {
	color: var(--e-global-color-primary);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-primary-font-weight);
}

.elementor-widget-sitemap .elementor-sitemap-item,
.elementor-widget-sitemap span.elementor-sitemap-list,
.elementor-widget-sitemap .elementor-sitemap-item a {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-sitemap .elementor-sitemap-item {
	color: var(--e-global-color-text);
}

.elementor-widget-breadcrumbs {
	font-family: var(--e-global-typography-secondary-font-family), Sans;
	font-weight: var(--e-global-typography-secondary-font-weight);
}

.elementor-widget-blockquote .elementor-blockquote__content {
	color: var(--e-global-color-text);
}

.elementor-widget-blockquote .elementor-blockquote__author {
	color: var(--e-global-color-secondary);
}

.elementor-widget-lottie {
	--caption-color: var(--e-global-color-text);
}

.elementor-widget-lottie .e-lottie__caption {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-title {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-videos-count {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side i {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-header .e-tabs-header-right-side svg {
	fill: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-video-playlist .e-tab-title .e-tab-title-text a {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tab-title .e-tab-duration {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title:where(.e-active, :hover) .e-tab-title-text {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title:where(.e-active, :hover) .e-tab-title-text a {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title:where(.e-active, :hover) .e-tab-duration {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-items-wrapper .e-section-title {
	color: var(--e-global-color-text);
}

.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-wrapper .e-inner-tab-title a {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content .e-inner-tab-text {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content button {
	color: var(--e-global-color-text);
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
}

.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content button:hover {
	color: var(--e-global-color-text);
}

.elementor-widget-paypal-button .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-paypal-button .elementor-message {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-stripe-button .elementor-button {
	font-family: var(--e-global-typography-accent-font-family), Sans;
	font-weight: var(--e-global-typography-accent-font-weight);
	background-color: var(--e-global-color-accent);
}

.elementor-widget-stripe-button .elementor-message {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

.elementor-widget-progress-tracker .current-progress-percentage {
	font-family: var(--e-global-typography-text-font-family), Sans;
	font-size: var(--e-global-typography-text-font-size);
	font-weight: var(--e-global-typography-text-font-weight);
	line-height: var(--e-global-typography-text-line-height);
}

@media(max-width:1024px) {
	.elementor-widget-image .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-text-editor {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-image-box .elementor-image-box-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-icon-box .elementor-icon-box-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-star-rating .elementor-star-rating__title {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-icon-list .elementor-icon-list-item>.elementor-icon-list-text,
	.elementor-widget-icon-list .elementor-icon-list-item>a {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-progress .elementor-title {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-testimonial .elementor-testimonial-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-tabs .elementor-tab-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-accordion .elementor-tab-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-toggle .elementor-tab-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-alert .elementor-alert-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-text-path {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-site-logo .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-post-excerpt .elementor-widget-container {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-post-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-post-featured-image .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-archive-posts .elementor-post__excerpt p {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-archive-posts .elementor-posts-nothing-found {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-posts .elementor-post__excerpt p {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-gallery .elementor-gallery-item__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-field-group>label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-field-type-html {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-field-group .elementor-field,
	.elementor-widget-form .elementor-field-subgroup label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-login .elementor-form-fields-wrapper label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-login .elementor-field-group .elementor-field,
	.elementor-widget-login .elementor-field-subgroup label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-login .elementor-widget-container .elementor-login__logged-in-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-hotspot .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-price-list .elementor-price-list-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-price-table .elementor-price-table__features-list li {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-price-table .elementor-price-table__additional_info {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-flip-box .elementor-flip-box__front .elementor-flip-box__layer__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-flip-box .elementor-flip-box__back .elementor-flip-box__layer__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-call-to-action .elementor-cta__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-testimonial-carousel .elementor-testimonial__text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-reviews .elementor-testimonial__text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-table-of-contents .elementor-toc__list-item {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-countdown .elementor-countdown-digits {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-countdown .elementor-countdown-expire--message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-search-form input[type="search"].elementor-search-form__input {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-search-form .elementor-search-form__submit {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-author-box .elementor-author-box__bio {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-post-info .elementor-icon-list-item {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-sitemap .elementor-sitemap-item,
	.elementor-widget-sitemap span.elementor-sitemap-list,
	.elementor-widget-sitemap .elementor-sitemap-item a {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-lottie .e-lottie__caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title:where(.e-active, :hover) .e-tab-title-text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-wrapper .e-inner-tab-title a {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content .e-inner-tab-text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-paypal-button .elementor-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-stripe-button .elementor-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-progress-tracker .current-progress-percentage {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}
}

@media(max-width:767px) {
	.elementor-widget-image .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-text-editor {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-image-box .elementor-image-box-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-icon-box .elementor-icon-box-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-star-rating .elementor-star-rating__title {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-icon-list .elementor-icon-list-item>.elementor-icon-list-text,
	.elementor-widget-icon-list .elementor-icon-list-item>a {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-progress .elementor-title {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-testimonial .elementor-testimonial-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-tabs .elementor-tab-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-accordion .elementor-tab-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-toggle .elementor-tab-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-alert .elementor-alert-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-text-path {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-site-logo .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-post-excerpt .elementor-widget-container {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-post-content {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-theme-post-featured-image .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-archive-posts .elementor-post__excerpt p {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-archive-posts .elementor-posts-nothing-found {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-posts .elementor-post__excerpt p {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-gallery .elementor-gallery-item__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-field-group>label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-field-type-html {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-field-group .elementor-field,
	.elementor-widget-form .elementor-field-subgroup label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-form .elementor-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-login .elementor-form-fields-wrapper label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-login .elementor-field-group .elementor-field,
	.elementor-widget-login .elementor-field-subgroup label {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-login .elementor-widget-container .elementor-login__logged-in-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-hotspot .widget-image-caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-price-list .elementor-price-list-description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-price-table .elementor-price-table__features-list li {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-price-table .elementor-price-table__additional_info {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-flip-box .elementor-flip-box__front .elementor-flip-box__layer__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-flip-box .elementor-flip-box__back .elementor-flip-box__layer__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-call-to-action .elementor-cta__description {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-testimonial-carousel .elementor-testimonial__text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-reviews .elementor-testimonial__text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-table-of-contents .elementor-toc__list-item {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-countdown .elementor-countdown-digits {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-countdown .elementor-countdown-expire--message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-search-form input[type="search"].elementor-search-form__input {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-search-form .elementor-search-form__submit {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-author-box .elementor-author-box__bio {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-post-info .elementor-icon-list-item {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-sitemap .elementor-sitemap-item,
	.elementor-widget-sitemap span.elementor-sitemap-list,
	.elementor-widget-sitemap .elementor-sitemap-item a {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-lottie .e-lottie__caption {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tab-title .e-tab-title-text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tabs-items-wrapper .e-tab-title:where(.e-active, :hover) .e-tab-title-text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-wrapper .e-inner-tab-title a {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-video-playlist .e-tabs-inner-tabs .e-inner-tabs-content-wrapper .e-inner-tab-content .e-inner-tab-text {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-paypal-button .elementor-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-stripe-button .elementor-message {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}

	.elementor-widget-progress-tracker .current-progress-percentage {
		font-size: var(--e-global-typography-text-font-size);
		line-height: var(--e-global-typography-text-line-height);
	}
}