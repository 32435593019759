* {
	box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

p {
	margin: 0 0 15px;
}

.elementor-post__excerpt p:after {
	content: "...";
}

h6.elementor-heading-title a {
	text-decoration: none;
}

.greenTxt {
	color: #47A164;
}

.disclm {
	font-size: 12px;
	display: block;
	margin-top: -5px;
}

.elementor-post__meta-data a,
.elementor-pagination a,
.elementor-post__title a,
.elementor-post-info a {
	text-decoration: none !important;
}

.dshedBrdr .elementor-widget-container {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='%236CD88DFF' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.greatDevices h3 {
	margin: 10px 0px;
}

.callBtn {
	color: #ffffff !important;
	display: inline-block;
	font-size: 16px;
	font-weight: 500;
	border-radius: 6px 6px 6px 6px;
	padding: 15px 55px 15px 55px;
	background-image: linear-gradient(223deg, #F99357 0%, #E76F2E 100%);
	margin: 20px 0;
}

.callBtn:before {
	content: "\f879";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	margin-right: 10px;
	opacity: 0.7;
}

.widget-image-caption.wp-caption-text b {
	font-size: 32px;
	text-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
}

.darkBG:before {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
	background: linear-gradient(223.23deg, #2F3E57 -10.12%, #1D2A3E 120.57%);
}

#update {
	background-position: center 150px;
}

.globalCallBtn .elementor-button,
.globalCallBtn .elementor-button:hover {
	color: #ffffff;
}

blockquote {
	border-left: 3px solid #6CD88D;
	padding-left: 50px;
	margin: 30px 0 20px;
}

.swiper-slide {
	cursor: grab;
}

.elementor-widget-text-editor p:last-child,
.elementor-widget-text-editor .elementor-widget-container p:last-child {
	margin-bottom: 0;
}

ul.elementor-nav-menu--dropdown a,
ul.elementor-nav-menu--dropdown a:focus,
ul.elementor-nav-menu--dropdown a:hover {
	border-left: none !important;
}

.elementor-button {
	cursor: pointer;
}

.elementor-widget-text-editor .expendText {
	display: none;
}

.elementor-widget-text-editor .expendreadmore {
	text-decoration: none;
	font-weight: 600;
	color: #6cd78d;
	cursor: pointer;
	display: block;
	margin: 20px;
}

.single .elementor-widget-text-editor a {
	text-decoration: underline;
}

.elementor-image-box-content p {
	margin: 0;
}

.elementor-widget-text-editor li {
	margin-bottom: 10px;
}

.elementor-button.menu-item a {
	font-size: 20px !important;

}

.elementor-button.menu-item a i {
	margin-right: 5px;
}

.elementor-widget-shortcode .elementor-button-link.elementor-button {
	color: #ffffff !important;
}

#stickyCTA {
	top: auto !important;
	bottom: 0;
}

body {
	margin: 0;
	padding: 0;
}

.hideDesktop,
.paged .featuredBlogHub,
body[class*="paged"] .featuredBlogHub {
	display: none;
}

#searchform div {
	display: flex;
}

#searchform input[type="number"] {
	border: none;
	height: 40px;
	border-radius: 5px;
	color: #000;
	text-align: center;
	width: 52%;
	margin: 0 0.5%;
	outline: none;
}

#searchform input[type="submit"] {
	border: none;
	font-size: 14px;
	font-weight: 500;
	background-image: none;
	background-color: #6CD88D;
	height: 40px;
	border-radius: 5px;
	color: #ffffff;
	text-align: center;
	width: 48%;
	margin: 0 0.5%;
	cursor: pointer;
	outline: none;
}

footer .copyright span {
	padding: 0 10px;
}

/*Home Page*/
.heroColumns {
	max-width: 1015px;
	min-height: 217px;
	/* background: linear-gradient(223.23deg, #2F3E57 -10.12%, #1D2A3E 120.57%); */
	background-image: linear-gradient(120deg, var(--e-global-color-ac969e5) 0%, var(--e-global-color-ac969e5) 100%);
}

.heroColumns .elementor-icon-box-title {
	margin-top: 5px;
}

.homeTabs .elementor-tabs-wrapper,
.tabs .elementor-tabs-wrapper {
	border: 1px solid #cfcfcf;
	background: linear-gradient(158.72deg, rgba(137, 152, 175, 0.08) 3.01%, rgba(137, 152, 175, 0.04) 103.3%);
	border-radius: 12px;
}

.tabs div[data-tab="2"],
.tabs div[data-tab="3"],
.tabs div[data-tab="4"] {
	max-width: 90%;
	margin: 30px auto 0;
}

.homeTabs.elementor-widget-tabs .elementor-tab-title,
.tabs.elementor-widget-tabs .elementor-tab-title {
	margin: 5px;
	position: relative;
	text-align: center;
	padding: 17px;
}

.homeTabs.elementor-widget-tabs .elementor-tab-title:after,
.tabs.elementor-widget-tabs .elementor-tab-title:after {
	content: "";
	height: 70%;
	position: absolute;
	right: 0;
	width: 1px;
	background: rgb(166 172 183 /30%);
	top: 7px;
}

.homeTabs.elementor-widget-tabs .elementor-tab-title:last-child::after,
.tabs.elementor-widget-tabs .elementor-tab-title:last-child::after {
	display: none;
}

.homeTabs .elementor-tab-title.elementor-active,
.tabs .elementor-tab-title.elementor-active {
	background: linear-gradient(223.23deg, #6CD88D -10.12%, #5ECB80 120.57%);
	backdrop-filter: blur(42px);
	border-radius: 10px;
	font-size: 18px !important;
}

.homeServicesTabs .elementor-tab-content {
	max-width: 100%;
	margin: 30px auto;
	padding: 0 !important;
}

.phone_tag h4 {
	margin: 0;
}

.elementor-heading-title {
	margin: 0;
}

.homeServicesTabs ul {
	padding-left: 20px;
	line-height: 1.8;
}

.faqToggle .elementor-tab-title.elementor-active {
	background-color: transparent !important;
}

.faqToggle .elementor-tab-title:before {
	/* content: "\f128"; */
	font-family: "Font Awesome 5 Free";
	color: #ffffff;
	padding: 8px 10px;
	border-radius: 100%;
	background: #1d2a3e;
	font-size: 10px;
	margin-right: 15px;
	height: 27px;
	width: 27px;
}

.carousel .elementor-posts--skin-classic {
	display: flex;
	flex-wrap: unset;
}

.swiperNav {
	position: absolute;
	top: -100px;
	left: 250px;
}

.single .swiperNav {
	left: 430px;
}

.btnPrev,
.btnNext {
	padding: 10px 12px;
	background: rgb(47 62 87 / 10%);
	transition: transform .2s;
	color: #2F3E57;
	border-radius: 100%;
	display: inline-block;
	margin: 10px;
	cursor: pointer;
	position: absolute;
	top: 0;
}

.btnNext {
	left: 60px;
}

.btnPrev:hover,
.btnNext:hover {
	transform: scale(1.5);
}

.elementor-post__text {
	display: grid;
}

.elementor-post__meta-data {
	order: 3
}

h3.elementor-post__title {
	min-height: 60px;
	align-items: center;
	display: inline-flex;
}

.home .elementor-post__excerpt {
	height: 100px;
	overflow: hidden;
}



.pricingTableHome .price span {
	font-size: 14px;
	font-weight: 400;
	display: block;
	padding: 5px;
	opacity: 0.4;
}

.pricingTableHome .elementor-container {
	align-items: center;
}

.pricingTableHome .elementor-icon-box-title {
	margin-top: 0px;
}

.testimonials .elementor-main-swiper {
	padding-bottom: 0;
}

.testimonials .swiper-wrapper,
.testimonials .swiper-slide {
	transition-property: opacity;
}

.testimonials .swiper-slide {
	opacity: 1;
}

.testimonials .swiper-slide.swiper-slide-active {
	opacity: 1;
}

.testimonials .elementor-testimonial__image img {
	min-height: 445px;
}

.testimonials .elementor-testimonial {
	align-items: center !important;
}

.testimonials .elementor-testimonial__content {
	margin-top: 20px;
}

.testimonials .elementor-testimonial__text h3 {
	color: #ffffff;
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 30px;
}

.testimonials .elementor-testimonial__cite {
	position: absolute;
	bottom: 0;
	text-align: left;
	width: auto !important;
}

.elementor-testimonial--align-center .elementor-testimonial {
	text-align: left !important;
}

.testimonials .elementor-testimonial__title {
	margin-top: 10px;
	color: #ffffff !important;
	opacity: 0.3;
}

.testimonials .elementor-swiper-button {
	padding: 10px 12px;
	background: rgb(255 255 255 / 10%);
	transition: transform .2s;
	color: #6CD88D;
	border-radius: 100%;
	display: inline-block;
	margin: 10px;
	cursor: pointer;
	height: 43px;
	width: 43px;
	transform: unset;
	position: absolute;
	top: unset !important;
	bottom: 20px !important;
	right: 90px !important;
}

.testimonials .elementor-swiper-button:hover {
	transform: scale(1.5);
	background: #6CD88D;
	color: #ffffff;
}

.testimonials .elementor-swiper-button.elementor-swiper-button-prev {
	left: unset;
	right: 150px !important;
}

.testimonials .swiper-pagination-bullets {
	width: auto;
	left: unset !important;
	right: 300px;
	bottom: 45px;
}

.testimonials .swiper-pagination-bullet {
	background: #ffffff !important;
}

.testimonials .swiper-pagination-bullet.swiper-pagination-bullet-active {
	width: 30px !important;
	border-radius: 5px;
}

.elementor-tab-title.elementor-tab-desktop-title {
	display: flex !important;
	align-items: center;
}

.elementor-tab-title.elementor-tab-desktop-title a {
	margin: 0 auto;
}

.serviceTab .elementor-widget-text-editor li,
.pricingTabs .elementor-widget-text-editor li {
	list-style: none;
	margin-bottom: 15px;
	display: inline-flex;
	font-size: 22px;
	line-height: 1.2;
}

.serviceTab .elementor-widget-text-editor ul,
.pricingTabs .elementor-widget-text-editor ul {
	padding-left: 10px;
}

.serviceTab .elementor-widget-text-editor li:before,
.pricingTabs .elementor-widget-text-editor li:before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f058";
	margin-right: 10px;
	color: #68d489;
	font-size: 26px;
}

.serviceTab .elementor-widget-button {
	margin-left: 15px;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
	bottom: -25px;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	width: 20px;
	border-radius: 5px;
}

.singlefaq h4 {
	margin-top: 15px;
}



/*About Us*/
.ourSpecialty .elementor-widget-icon-box .elementor-icon-box-title {
	margin: 10px 0 25px;
	min-height: 64px;
	display: flex;
	align-items: center;
}

.ourSpecialty .elementor-view-stacked .elementor-icon {
	padding: 0.6em;
}

.ourSpecialty .featured.elementor-widget-icon-box .readBtn {
	text-decoration: none;
}

.elementor-widget-icon-box .readBtn {
	color: #ffffff;
	display: table;
	font-size: 18px;
	font-weight: 700;
	border-radius: 6px 6px 6px 6px;
	padding: 15px 55px 15px 55px;
	background-image: linear-gradient(223deg, #F99357 0%, #E76F2E 100%);
	margin: 15px 0 0;
	text-decoration: none;
}

.readBtn:hover {
	opacity: 0.9;
}

.ourSpecialty .featured.elementor-widget-icon-box .elementor-icon-box-description {
	margin-left: -94px;
}

.elementor-widget-icon-box .readBtn,
.elementor-widget-icon-box .callBtn {
	text-decoration: none;
}

.elementor-widget-icon-box .readBtn {
	color: #ffffff;
	display: table;
	font-size: 18px;
	font-weight: 600;
	border-radius: 6px 6px 6px 6px;
	padding: 15px 55px 15px 55px;
	background-image: linear-gradient(223deg, #F99357 0%, #E76F2E 100%);
	margin: 15px 0 0;
	text-decoration: none;
}

.callBtn:hover,
.readBtn:hover {
	opacity: 0.9;
}

.contactUs .elementor-widget-icon-box .readBtn,
.contactUs .elementor-widget-icon-box .callBtn {
	display: table;
	margin: 20px auto 0;
}

.contactWidget .elementor-icon-box-title {
	min-height: 48px;
	display: inline-flex;
	align-items: center;
}

.contactWidget .elementor-widget-container {
	background: linear-gradient(158.72deg, rgba(137, 152, 175, 0.08) 3.01%, rgba(137, 152, 175, 0.04) 103.3%);
	backdrop-filter: blur(18px);
	-webkit-backdrop-filter: blur(18px);
}

@-moz-document url-prefix() {
	.contactWidget .elementor-widget-container {
		background-color: #ffffff;
	}
}

/* Blog Hub*/
.blogHub .elementor-search-form__container {
	position: relative;
}

.elementor-share-buttons--shape-circle .elementor-share-btn {
	height: 48px;
}

.blogHub .elementor-search-form__container:before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f002";
	color: #2f3e57b3;
	position: absolute;
	left: 22px;
	top: 22px;
}

.blogHub .elementor-post__excerpt {
	margin-right: 25px;
}

.elementor-search-form__container .elementor-search-form__submit {
	border-radius: 6px;
}

.blogHub .elementor-search-form__input {
	padding-left: 60px !important;
}

.elementor-search-form__submit {
	cursor: pointer;
}

.blogGrid .elementor-post__thumbnail {
	border-radius: 12px;
	margin: 10px;
}

.blogGrid .elementor-post__meta-data {
	padding: 20px 30px 30px !important;
}

.blogGrid .elementor-post__avatar {
	position: absolute !important;
	top: unset !important;
	bottom: 30px !important;
	max-width: 72px;
}

.blogGrid .elementor-post-author {
	font-size: 14px;
	font-weight: 600;
	color: #2F3E57;
}

.blogGrid.elementor-posts--thumbnail-top .elementor-post__thumbnail__link {
	margin-bottom: 10px;
}

.blogGrid .elementor-post__meta-data span:before {
	padding: 0px;
}

.blogGrid .elementor-pagination {
	padding: 50px 0;
	border-top: solid 1px rgb(47 62 87 / 10%);
}

.blogGrid .elementor-post__badge {
	right: unset !important;
	left: 0;
	top: 160px !important;
}

.page-numbers.prev {
	float: left;
}

.page-numbers.next {
	float: right;
}

.elementor-pagination .next span,
.elementor-pagination .prev span {
	background: rgb(47 62 87 / 10%);
	padding: 10px 12px;
	border-radius: 100%;
}

span.page-numbers.prev,
span.page-numbers.next {
	opacity: 0.3;
}

.elementor-pagination .prev span {
	margin-right: 18px;
}

.elementor-pagination .next span {
	margin-left: 18px;
}

.elementor-pagination .page-numbers {
	padding: 10px 16px;
	border-radius: 6px;
}

.elementor-pagination .page-numbers.current {
	background: #eaebee;
}

.elementor-pagination .page-numbers:hover {
	background: #eaebee;
}

.elementor-pagination .next:hover,
.elementor-pagination .prev:hover {
	background: transparent;
}

.elementor-pagination .next span:hover,
.elementor-pagination .prev span:hover {
	opacity: 0.8;
}

.featuredBlog .elementor-post__meta-data {
	order: 2;
}

.featuredBlog .elementor-post__excerpt {
	order: 3;
}

.featuredBlog .elementor-post__title {
	order: 1;
}

.featuredBlog .elementor-post__thumbnail__link {
	background-image: url('../../assests/images/05/featured_imgBG.svg');
	background-repeat: no-repeat;
	background-position: 0 200px;
	padding: 100px 0;
}

.featuredBlog .elementor-post__thumbnail {
	max-width: 422px;
	margin: 0 auto;
	border-radius: 300px 300px 0 0;
}

.featuredBlog .elementor-post {
	align-items: center;
}

.featuredBlog .elementor-post__excerpt {
	height: auto;
}

.featuredBlog .elementor-post__read-more {
	padding: 20px;
	background: linear-gradient(223.23deg, #F99357 -10.12%, #E76F2E 120.57%);
	box-shadow: 0px 10px 16px rgba(244, 137, 75, 0.1);
	border-radius: 6px;
	width: 185px;
	text-align: center;
	order: 4;
}

.featuredBlog .elementor-post__read-more:hover {
	opacity: 0.8;
}

.featuredBlog .elementor-post__text:after {
	content: "";
	background-image: url('../../assests/images/05/arrow_design.svg');
	position: absolute;
	bottom: 0;
	left: 150px;
	width: 145px;
	height: 122px;
}

.featuredBlog .elementor-post__text {
	padding-top: 60px;
	padding-bottom: 90px;
	position: relative;
	margin-top: 30px;
}

.featuredBlog .elementor-post-time {
	position: absolute;
	left: 0;
	top: 0;
	padding: 12px 15px;
	background: #d1f8de;
	font-size: 16px;
	font-weight: 500;
	border-radius: 6px;
}

.featuredBlog .elementor-post-time a {
	color: #4ec573;
}

.featuredBlog .elementor-post-time:before {
	display: none;
}

.elementor-post-author .avatar {
	margin-right: 10px;
	border-radius: 100%;
	margin-bottom: -12px;
}

.elementor-post__meta-data .elementor-post-author {
	font-size: 16px;
	font-weight: 600;
	color: #2F3E57;
}

.elementor-post__meta-data span:before {
	padding: 0 15px;
	color: #bfc4cf;
}

.pricing_features {
	display: flex;
	column-gap: 20px;
	align-items: center;
}

.pricing_features .f_img {
	max-width: 45%;
}

.pricing_features .features {
	max-width: 55%;
	padding-left: 20px
}

.pricing_features .features ul {
	margin-left: 0;
	padding-left: 0px;
}

.pricing_features .features li {
	list-style: none;
	margin-bottom: 15px;
	display: inline-flex;
	font-size: 24px;
	line-height: 1.2;
}

.pricing_features .features li:before {
	font-family: var(--e-global-typography-accent-font-family), Font Awesome 5 Free;
	font-weight: 900;
	/* content: "\f058"; */
	margin-right: 10px;
	color: #68d489;
	font-size: 26px;
}

.tabsSteps .elementor-image-box-content {
	text-align: center;
}

.tabsSteps .elementor-image-box-img {
	min-height: 290px;
}

.pricing_features .priceTag {
	margin: 20px 20px 20px 0;
}

.pricing_features .ctaBtn {
	font-size: 16px;
	font-weight: 500;
	border-radius: 6px;
	padding: 10px 25px;
	text-decoration: none;
	display: inline-block;
	color: var(--e-global-color-29f88a8);
	background-color: transparent;
	background-image: linear-gradient(223deg, #F99357 0%, #E76F2E 100%);
	margin: 0px;
}

.pricing_features .callBtn:before {
	content: "\f879";
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

.pricing_features .ctaBtn:hover {
	opacity: 0.9;
}

.pricing_features .ctaSpeed {
	padding: 0px 75px 65px 0px;
	background-image: url('../../assests/images/05/black_arrow.svg');
	background-position: 270px 10px;
	background-repeat: no-repeat;
	background-size: 120px auto;
}



/*Landing Page #1 */

.updateIconBoxs .elementor-row {
	column-gap: 24px;
}

.updateIconBoxs .elementor-widget-icon-box .elementor-icon-box-title {
	margin-top: 5px;
	display: flex;
	align-items: center;
	min-height: 48px;
}

.updateIconBoxs .elementor-widget-icon-box .elementor-icon-box-description {
	margin-left: -70px;
}

.elementor-button i {
	opacity: 0.7;
}

.elementor-widget-icon-box h5 {
	margin-top: 0;
}

.inColGap .elementor-row {
	column-gap: 65px;
}

.phone_tag .elementor-widget-container {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%236CD88DFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 2px;
}

.grnSpan span {
	color: #47A164;
}

.faqToggle .elementor-tab-title {
	display: flex;
	align-items: center;
}

.faqToggle .elementor-toggle-icon {
	order: 2;
	margin-left: auto;
	padding-left: 10px;
}

.elementor-tab-title a {
	text-decoration: none !important;
}

.faqToggle .elementor-tab-content a {
	display: inline-block;
	color: #26364b;
}

/*Location*/
.pricingBoxes .elementor-container {
	column-gap: 15px;
}

.packFeat p {
	margin-bottom: 10px;
}

.tabs .elementor-tabs-wrapper {
	max-width: 90%;
	margin: 0 auto;
}

.priceTag {
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='2' ry='2' stroke='%236CD88DFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	padding: 5px 5px 5px 10px;
	color: #43A461;
	background-color: #D9F4E2;
	border-radius: 2px;
	font-family: var(--e-global-typography-primary-font-family), Sans;
	width: max-content;
	margin: 0 auto;
}

.priceTagCont {
	display: flex;
	align-items: center;
}

.priceTag .offerPrice {
	font-size: 64px;
	font-weight: 700;
	line-height: 1;
}

.priceTag .offerPrice:before {
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
	content: "\f155";
	font-size: 32px;
	color: #43A461;
	position: relative;
	top: -15px;
	margin-right: 5px;
}

.priceTag .regPrice {
	padding: 5px 10px 5px;
	font-size: 20px;
	line-height: 1;
}

.priceTag .cents {
	font-size: 34px;
	font-weight: 700;
}

.priceTag .billTenure,
.priceTag .bundle {
	font-size: 11px;
	line-height: 10px;
	display: block;
}

.bundle {
	color: #9299a7;
}

.popular .elementor-widget-wrap {
	transform: scale(1.1);
}






/*Landing Page #2 */
.landing2 .elementor-image-box-title {
	margin: 0;
}

.step1 .elementor-image-box-content {
	padding-right: 50px;
	margin-top: -10px;
}

.step3 .elementor-image-box-content {
	padding-left: 50px;
	margin-top: -17px;
}

.faqAltSec {
	background-position: right 300px;
}

.faqAlt .elementor-accordion-item {
	margin-bottom: 20px;
	border-radius: 12px;
	overflow: hidden;
}

.faqAlt .elementor-accordion .elementor-tab-title {
	padding: 20px 30px 20px 50px;
}

.faqAlt .elementor-accordion-icon {
	background: #29384F;
	border-radius: 30px;
	font-size: 10px;
	display: block !important;
	height: 30px;
	width: 30px !important;
	text-align: center !important;
	padding: 10px 0;
	margin-top: -7px;
	margin-left: 10px;
}

.faqAlt .elementor-tab-title {
	background: url('../../assests/images/05/Subtract.svg');
	padding-left: 30px;
	background-position: 30px center;
	background-repeat: no-repeat;
}

.faqAlt .elementor-tab-content {
	padding: 5px 30px 1px !important;
}

.voicePakage h4 {
	margin: 0;
}

.IconBoxAccording .elementor-icon-box-title .btn {
	height: 44px;
	width: 44px;
	border-radius: 100%;
	text-align: center;
	background: #29384F;
	color: #6CD88D;
	font-weight: 300;
	cursor: pointer;
	line-height: 44px;
	font-size: 26px;
	position: absolute;
	right: 40px;
}

.IconBoxAccording .elementor-icon-box-title {
	margin: 15px 0 !important;
	min-height: 50px;
	display: flex;
	align-items: center;
	padding-right: 100px;
}

.IconBoxAccording.elementor-widget-icon-box .elementor-icon-box-description {
	margin-left: -100px;
	margin-top: 40px;
	display: none;
}

.IconBoxAccording.open.elementor-widget-icon-box .elementor-icon-box-description {
	display: block;
}







/*Article Page*/
.single .elementor-post-info__terms-list-item {
	padding: 12px 15px;
	background: #d1f8de;
	border-radius: 6px;
}

.elementor-share-btn__icon i {
	color: #2F3E57;
}

.elementor-post-info__item--type-author {
	color: #2f3e57 !important;
	font-weight: 600;
}

.elementor-icon-list-icon {
	margin-right: 10px;
}

.elementor-posts--thumbnail-top .elementor-post {
	height: unset;
}

.single .elementor-widget-text-editor {
	font-size: 18px !important;
	line-height: 1.9;
}

.single .elementor-widget-text-editor p {
	margin-bottom: 40px;
}

.elementor-post__card .elementor-post__meta-data {
	border-top: none !important;
}

.elementor-share-buttons--skin-boxed span.elementor-share-btn__icon,
.elementor-share-buttons--skin-framed span.elementor-share-btn__icon {
	margin: 0;
}

ul.state__cities {
	display: flex;
	list-style: none;
	padding-left: 0;
	column-gap: 2%;
	flex-wrap: wrap;
}

.state__cities li {
	width: 48%;
	position: relative;
	padding-left: 30px;
}

.state__cities li a {
	display: block;
	font-size: 14px;
	color: #1d2a3e;
	margin-bottom: 10px;
}

.state__cities li a:before {
	content: "\f058";
	font-family: "Font Awesome 5 Free";
	font-weight: 400;
	color: var(--e-global-color-accent);
	font-size: 14px;
	position: absolute;
	left: 0;
}

.state__cities li a:hover {}


#stickyCTA.stickyCTA {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 10px;
	max-width: 1170px;
	margin: 0 auto;
	background: #26364b;
	z-index: 9999;
}

#stickyCTA.stickyCTA h2 {
	color: #ffffff;
}

#stickyCTA.stickyCTA h2 br {
	display: none;
}






@media(max-width:1023px) {
	.elementor-widget-image-carousel .swiper-slide {
		padding: 30px;
		margin: 0 !important;
	}

	.testimonials .elementor-testimonial__cite {
		position: relative;
	}

	.pricingBoxes .elementor-container {
		row-gap: 15px;
		padding-left: 15px;
	}

	.blogGrid .elementor-post__badge {
		top: 125px !important;
	}

	.voicePackSec:before,
	.elementor-button.menu-item {
		display: none;
	}

	.homeTabs .elementor-tab-title.elementor-active,
	.tabs .elementor-tab-title.elementor-active,
	.homeTabs .elementor-tab-title,
	.tabs .elementor-tab-title {
		font-size: 16px !important;
	}

	.tabs .elementor-tabs-wrapper,
	.tabs div[data-tab="2"],
	.tabs div[data-tab="3"],
	.tabs div[data-tab="4"] {
		max-width: 100%;
	}

	.pricing_features {
		align-items: normal;
	}

	.tabsSteps .elementor-image-box-img {
		min-height: auto;
		width: 100%;
	}

	.priceTag {
		width: auto;
	}
}

@media(max-width:767px) {
	.nopad ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.nopad li {
		padding: 0;
		margin: 0;
	}

	.homeTabs.elementor-widget-tabs .elementor-tab-title:after,
	.hideMobile,
	.elementor-icon-box-wrapper br,
	.page-numbers.prev,
	.page-numbers.next {
		display: none;
	}

	.hideDesktop {
		display: block;
	}

	.blogGrid .elementor-post__meta-data {
		padding: 20px 20px 30px !important
	}

	.elementor-post__meta-data .elementor-post-author {
		font-size: 12px;
	}

	.goFastSec .blueSec .elementor-widget-wrap {
		background-color: #2A384F !important;
		border-radius: 20px !important;
	}

	.goFastSec .greenSec .elementor-widget-wrap {
		background-color: #47A164 !important;
		border-radius: 20px !important;
	}

	.goFastSec .elementor-icon-box-description,
	.goFastSec .elementor-icon-box-title {
		color: #ffffff !important;
		text-align: left;
	}

	.goFastSec .elementor-icon-box-title {
		margin-top: 5px;
		margin-bottom: 0;
		display: flex;
		align-items: center;
		min-height: 48px;
	}

	.goFastSec .elementor-widget-icon-box .elementor-icon-box-description {
		color: rgb(255 255 255 / 70%) !important;
		margin-right: -85px;
		margin-top: 30px;
		font-size: 14px !important;
	}

	.goFastSec .elementor-widget-icon-box svg {
		background: #eeeff2;
		border-radius: 5px;
	}

	.locationLinks {
		display: none;
	}

	.elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper {
		align-items: center;
	}

	.updateIconBoxs .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper,
	.ourSpecialty .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper,
	.IconBoxAccording.elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper {
		align-items: unset;
	}

	.heroColumns {
		margin-right: 50px;
	}

	.goFastSec .elementor-icon-box-icon,
	.pricing_features {
		display: block !important;
	}

	.goFastSec .elementor-shape-circle .elementor-icon {
		border-radius: 10%;
	}

	.elementor-tab-title.elementor-tab-mobile-title {
		display: none;
	}

	.elementor-tabs .elementor-tabs-wrapper {
		display: flex !important;
		flex-wrap: wrap;
	}

	.homeTabs.elementor-widget-tabs .elementor-tab-title,
	.tabs.elementor-widget-tabs .elementor-tab-title {
		width: calc(50% - 10px);
		border: solid 1px #d4d4d4 !important;
		border-radius: 10px;
	}

	.tabs .elementor-tabs-wrapper {
		max-width: 100%;
		background: transparent;
		border: none;
	}

	.homeTabs.elementor-widget-tabs .elementor-tab-title:after,
	.tabs.elementor-widget-tabs .elementor-tab-title:after {
		display: none;
	}

	.testimonialsSec {
		background-position: -20px 600px !important;
		background-size: 900px;
	}

	.testimonials .elementor-testimonial {
		display: block !important;
	}

	.testimonials .elementor-testimonial__content {
		margin: 20px 0 40px 0;
	}

	.testimonials .elementor-testimonial__text h3 {
		font-size: 18px;
		line-height: 1.4;
	}

	.testimonials .elementor-testimonial__image {
		min-height: 350px;
	}

	.testimonials .elementor-testimonial__image img {
		min-height: 350px;
	}

	.testimonials .elementor-swiper-button {
		bottom: 420px !important;
		right: 0 !important;
	}

	.testimonials .elementor-swiper-button.elementor-swiper-button-prev {
		right: 60px !important;
	}

	.testimonials .swiper-pagination-bullets {
		bottom: 445px;
	}

	.swiperNav {
		top: -120px;
	}

	.btnNext {
		left: 20px;
	}

	.btnPrev {
		left: -40px;
	}

	.featuredBlog .elementor-post__text {
		text-align: center;
	}

	.featuredBlog .elementor-post {
		display: grid !important;
	}

	.featuredBlog .elementor-post__thumbnail__link {
		margin: 0px auto !important;
		order: 2;
		padding: 0;
	}

	.featuredBlog .elementor-post__text {
		order: 1;
	}

	.featuredBlog .elementor-post__read-more {
		margin: 0 auto;
	}

	.featuredBlog .elementor-post__text:after {
		left: 220px;
	}

	.featuredBlog .elementor-post-time {
		right: 0;
		width: fit-content;
		margin: auto;
	}

	.single .swiperNav {
		top: -100px;
		left: 280px;
	}

	.elementor-posts .elementor-post__meta-data {
		line-height: 2.2;
	}

	.popular .elementor-widget-wrap {
		transform: scale(1);
	}

	.elementor-widget-text-editor img {
		max-width: 112%;
		margin-left: -25px;
	}

	blockquote {
		padding-left: 20px;
	}

	.blogGrid .elementor-post__badge {
		top: 155px !important;
	}

	.step1 .elementor-image-box-content {
		padding-right: 0px;
		margin-top: 0px;
	}

	.step3 .elementor-image-box-content {
		padding-left: 0px;
		margin-top: 0px;
	}

	.voicePackSec .elementor-widget-text-editor ul {
		padding-left: 0;
		list-style: none;
	}

	.IconBoxAccording.elementor-widget-icon-box .elementor-icon-box-description {
		margin-left: -50px;
	}

	.IconBoxAccording .elementor-icon-box-title {
		margin: 5px 0 !important;
	}

	.IconBoxAccording .elementor-icon-box-title .btn {
		right: 20px;
	}

	.IconBoxAccording .elementor-icon-box-title {
		padding-right: 50px;
	}

	.pricingBoxes .elementor-container {
		padding-left: 0px;
	}

	.pricing_features .f_img,
	.pricing_features .features {
		max-width: 100%;
		padding: 0;
	}

	.pricing_features .priceTag {
		font-size: 20px;
	}

	.homeTabs .elementor-tabs-wrapper,
	.tabs .elementor-tabs-wrapper {
		border: none;
		background: none;
	}

	#stickyCTA.stickyCTA {
		max-width: 75%;
		left: 10px;
		right: auto;
	}
}

@media(max-width:375px) {
	.blogGrid .elementor-post__badge {
		top: 27% !important;
	}

	.single .swiperNav {
		left: 270px;
	}


	.btnNext {
		left: -25px;
	}

	.btnPrev {
		left: -70px;
	}

	#stickyCTA.stickyCTA {
		width: 68%;
	}

	#stickyCTA.stickyCTA h2 {
		font-size: 12px;
	}

	#stickyCTA.stickyCTA h6 a {
		font-size: 14px;
	}

	#stickyCTA.stickyCTA .elementor-element-5dd6475 .elementor-element-populated {
		padding: 10px 10px 0;
	}

	#stickyCTA.stickyCTA .elementor-element-c757507 .elementor-element-populated {
		padding-bottom: 6px;
	}

	#stickyCTA.stickyCTA .elementor-element-5dd6475 .elementor-element-populated .elementor-widget-container {
		width: 100%;
		text-align: center;
		padding: 0;
		margin: 0;
	}

	#stickyCTA.stickyCTA .elementor-element-de6bb48 .elementor-button {
		padding: 10px;
	}
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-wrapper {
	width: 25%;
	-ms-flex-negative: 0;
	flex-shrink: 0
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active {
	border-right-style: none
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:after,
.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:before {
	height: 999em;
	width: 0;
	right: 0;
	border-right-style: solid
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:before {
	top: 0;
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%)
}

.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tab-desktop-title.elementor-active:after {
	top: 100%
}

.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title {
	display: table-cell
}

.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active {
	border-bottom-style: none
}

.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:after,
.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:before {
	bottom: 0;
	height: 0;
	width: 999em;
	border-bottom-style: solid
}

.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:before {
	right: 100%
}

.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-desktop-title.elementor-active:after {
	left: 100%
}

.elementor-widget-tabs .elementor-tab-content,
.elementor-widget-tabs .elementor-tab-title,
.elementor-widget-tabs .elementor-tab-title:after,
.elementor-widget-tabs .elementor-tab-title:before,
.elementor-widget-tabs .elementor-tabs-content-wrapper {
	border: 1px #d4d4d4
}

.elementor-widget-tabs .elementor-tabs {
	text-align: left
}

.elementor-widget-tabs .elementor-tabs-wrapper {
	overflow: hidden
}

.elementor-widget-tabs .elementor-tab-title {
	cursor: pointer;
	outline: var(--focus-outline, none)
}

.elementor-widget-tabs .elementor-tab-desktop-title {
	position: relative;
	padding: 20px 25px;
	font-weight: 700;
	line-height: 1;
	border: solid transparent
}

.elementor-widget-tabs .elementor-tab-desktop-title.elementor-active {
	border-color: #d4d4d4
}

.elementor-widget-tabs .elementor-tab-desktop-title.elementor-active:after,
.elementor-widget-tabs .elementor-tab-desktop-title.elementor-active:before {
	display: block;
	content: "";
	position: absolute
}

.elementor-widget-tabs .elementor-tab-mobile-title {
	padding: 10px;
	cursor: pointer
}

.elementor-widget-tabs .elementor-tab-content {
	padding: 20px;
	display: none
}

@media (max-width:767px) {

	.elementor-tabs .elementor-tab-content,
	.elementor-tabs .elementor-tab-title {
		border-style: solid solid none
	}

	.elementor-tabs .elementor-tabs-wrapper {
		display: none
	}

	.elementor-tabs .elementor-tabs-content-wrapper {
		border-bottom-style: solid
	}

	.elementor-tabs .elementor-tab-content {
		padding: 10px
	}
}

@media (min-width:768px) {

	.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}

	.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column
	}

	.elementor-widget-tabs.elementor-tabs-view-vertical .elementor-tabs-content-wrapper {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		border-style: solid solid solid none
	}

	.elementor-widget-tabs.elementor-tabs-view-horizontal .elementor-tab-content {
		border-style: none solid solid
	}

	.elementor-widget-tabs.elementor-tabs-alignment-center .elementor-tabs-wrapper,
	.elementor-widget-tabs.elementor-tabs-alignment-end .elementor-tabs-wrapper,
	.elementor-widget-tabs.elementor-tabs-alignment-stretch .elementor-tabs-wrapper {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}

	.elementor-widget-tabs.elementor-tabs-alignment-center .elementor-tabs-wrapper {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center
	}

	.elementor-widget-tabs.elementor-tabs-alignment-end .elementor-tabs-wrapper {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end
	}

	.elementor-widget-tabs.elementor-tabs-alignment-stretch.elementor-tabs-view-horizontal .elementor-tab-title {
		width: 100%
	}

	.elementor-widget-tabs.elementor-tabs-alignment-stretch.elementor-tabs-view-vertical .elementor-tab-title {
		height: 100%
	}

	.elementor-tabs .elementor-tab-mobile-title {
		display: none
	}
}

.elementor-widget-image-carousel .swiper-container {
	position: static
}

.elementor-widget-image-carousel .swiper-container .swiper-slide figure {
	line-height: inherit
}

.elementor-widget-image-carousel .swiper-slide {
	text-align: center
}

.elementor-image-carousel-wrapper:not(.swiper-container-initialized) .swiper-slide {
	max-width: calc(100% / var(--e-image-carousel-slides-to-show, 3))
}

.elementor-toggle {
	text-align: left
}

.elementor-toggle .elementor-tab-title {
	font-weight: 700;
	line-height: 1;
	margin: 0;
	padding: 15px;
	border-bottom: 1px solid #d4d4d4;
	cursor: pointer;
	outline: none
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon {
	display: inline-block;
	width: 1em
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon svg {
	-webkit-margin-start: -5px;
	margin-inline-start: -5px;
	width: 1em;
	height: 1em
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon.elementor-toggle-icon-right {
	float: right;
	text-align: right
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon.elementor-toggle-icon-left {
	float: left;
	text-align: left
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-closed {
	display: block
}

.elementor-toggle .elementor-tab-title .elementor-toggle-icon .elementor-toggle-icon-opened {
	display: none
}

.elementor-toggle .elementor-tab-title.elementor-active {
	border-bottom: none
}

.elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-closed {
	display: none
}

.elementor-toggle .elementor-tab-title.elementor-active .elementor-toggle-icon-opened {
	display: block
}

.elementor-toggle .elementor-tab-content {
	padding: 15px;
	border-bottom: 1px solid #d4d4d4;
	display: none
}

@media (max-width:767px) {
	.elementor-toggle .elementor-tab-title {
		padding: 12px
	}

	.elementor-toggle .elementor-tab-content {
		padding: 12px 10px
	}
}

.mobno {
	display: none;
}

@media only screen and (max-width: 600px) {
	.mobno {
		display: block;
		text-align: center
	}

	.mobnotext {
		font-size: 25px !important;
		font-weight: 900 !important;
	}
}