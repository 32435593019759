/*! elementor - v3.6.7 - 03-07-2022 */
.elementor-widget-icon-box .elementor-icon-box-wrapper {
    display: block;
    text-align: center
}

.elementor-widget-icon-box .elementor-icon-box-icon {
    margin-bottom: var(--icon-box-icon-margin, 15px);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:768px) {
    .elementor-widget-icon-box.elementor-vertical-align-top .elementor-icon-box-wrapper {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .elementor-widget-icon-box.elementor-vertical-align-middle .elementor-icon-box-wrapper {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .elementor-widget-icon-box.elementor-vertical-align-bottom .elementor-icon-box-wrapper {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end
    }
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper,
.elementor-widget-icon-box.elementor-position-right .elementor-icon-box-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon,
.elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.elementor-widget-icon-box.elementor-position-right .elementor-icon-box-wrapper {
    text-align: right;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
    margin-left: var(--icon-box-icon-margin, 15px);
    margin-right: 0;
    margin-bottom: unset
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-wrapper {
    text-align: left;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row
}

.elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon {
    margin-right: var(--icon-box-icon-margin, 15px);
    margin-left: 0;
    margin-bottom: unset
}

.elementor-widget-icon-box.elementor-position-top .elementor-icon-box-wrapper {
    display: block;
    text-align: center;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
    -ms-flex-direction: unset;
    flex-direction: unset
}

.elementor-widget-icon-box.elementor-position-top .elementor-icon-box-icon {
    margin-bottom: var(--icon-box-icon-margin, 15px);
    margin-right: auto;
    margin-left: auto
}

@media (min-width:-1px) {

    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-wrapper {
        text-align: right;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .elementor-widget-icon-box.elementor-widescreen-position-right .elementor-icon-box-icon {
        margin-left: var(--icon-box-icon-margin, 15px);
        margin-right: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-wrapper {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .elementor-widget-icon-box.elementor-widescreen-position-left .elementor-icon-box-icon {
        margin-right: var(--icon-box-icon-margin, 15px);
        margin-left: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-widescreen-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }

    .elementor-widget-icon-box.elementor-widescreen-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto
    }
}

@media (max-width:-1px) {

    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-wrapper {
        text-align: right;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .elementor-widget-icon-box.elementor-laptop-position-right .elementor-icon-box-icon {
        margin-left: var(--icon-box-icon-margin, 15px);
        margin-right: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-wrapper {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .elementor-widget-icon-box.elementor-laptop-position-left .elementor-icon-box-icon {
        margin-right: var(--icon-box-icon-margin, 15px);
        margin-left: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-laptop-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }

    .elementor-widget-icon-box.elementor-laptop-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto
    }
}

@media (max-width:-1px) {

    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-wrapper {
        text-align: right;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-right .elementor-icon-box-icon {
        margin-left: var(--icon-box-icon-margin, 15px);
        margin-right: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-wrapper {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-left .elementor-icon-box-icon {
        margin-right: var(--icon-box-icon-margin, 15px);
        margin-left: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }

    .elementor-widget-icon-box.elementor-tablet_extra-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto
    }
}

@media (max-width:1024px) {

    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-wrapper {
        text-align: right;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .elementor-widget-icon-box.elementor-tablet-position-right .elementor-icon-box-icon {
        margin-left: var(--icon-box-icon-margin, 15px);
        margin-right: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-wrapper {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .elementor-widget-icon-box.elementor-tablet-position-left .elementor-icon-box-icon {
        margin-right: var(--icon-box-icon-margin, 15px);
        margin-left: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-tablet-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }

    .elementor-widget-icon-box.elementor-tablet-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto
    }
}

@media (max-width:-1px) {

    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-wrapper {
        text-align: right;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-right .elementor-icon-box-icon {
        margin-left: var(--icon-box-icon-margin, 15px);
        margin-right: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-wrapper {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-left .elementor-icon-box-icon {
        margin-right: var(--icon-box-icon-margin, 15px);
        margin-left: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }

    .elementor-widget-icon-box.elementor-mobile_extra-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto
    }
}

@media (max-width:767px) {

    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper,
    .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }

    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-icon {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-wrapper {
        text-align: right;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    .elementor-widget-icon-box.elementor-mobile-position-right .elementor-icon-box-icon {
        margin-left: var(--icon-box-icon-margin, 15px);
        margin-right: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-wrapper {
        text-align: left;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row
    }

    .elementor-widget-icon-box.elementor-mobile-position-left .elementor-icon-box-icon {
        margin-right: var(--icon-box-icon-margin, 15px);
        margin-left: 0;
        margin-bottom: unset
    }

    .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-wrapper {
        display: block;
        text-align: center;
        -webkit-box-orient: unset;
        -webkit-box-direction: unset;
        -ms-flex-direction: unset;
        flex-direction: unset
    }

    .elementor-widget-icon-box.elementor-mobile-position-top .elementor-icon-box-icon {
        margin-bottom: var(--icon-box-icon-margin, 15px);
        margin-right: auto;
        margin-left: auto
    }

    .elementor-widget-icon-box.elementor-position-left .elementor-icon-box-icon,
    .elementor-widget-icon-box.elementor-position-right .elementor-icon-box-icon {
        display: block;
        -webkit-box-flex: unset;
        -ms-flex: unset;
        flex: unset
    }
}

.elementor-widget-icon-box .elementor-icon-box-title a {
    color: inherit
}

.elementor-widget-icon-box .elementor-icon-box-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.elementor-widget-icon-box .elementor-icon-box-description {
    margin: 0
}