.elementor-37130 .elementor-element.elementor-element-afdff74>.elementor-container>.elementor-column>.elementor-widget-wrap {
	align-content: center;
	align-items: center;
}

.elementor-37130 .elementor-element.elementor-element-afdff74:not(.elementor-motion-effects-element-type-background),
.elementor-37130 .elementor-element.elementor-element-afdff74>.elementor-motion-effects-container>.elementor-motion-effects-layer {
	/* background-color: var(--e-global-color-859d4d9); */
	background-color: darkgreen;
}

.elementor-37130 .elementor-element.elementor-element-afdff74 {
	transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
	padding: 10px 0px 10px 0px;
	z-index: 100;
}

.elementor-37130 .elementor-element.elementor-element-afdff74>.elementor-background-overlay {
	transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-37130 .elementor-element.elementor-element-ebb3272 {
	text-align: left;
}

.elementor-37130 .elementor-element.elementor-element-ebb3272 img {
	width: 252px;
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-menu-toggle {
	margin-right: auto;
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu .elementor-item {
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--main .elementor-item {
	color: var(--e-global-color-29f88a8);
	fill: var(--e-global-color-29f88a8);
	/* padding-left: 14px;
	padding-right: 14px; */
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-menu-toggle {
	color: var(--e-global-color-29f88a8);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown {
	background-color: var(--e-global-color-859d4d9);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a:hover,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a.elementor-item-active,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a.highlighted,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-menu-toggle:hover {
	color: var(--e-global-color-primary);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a:hover,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a.elementor-item-active,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a.highlighted {
	background-color: var(--e-global-color-ecb3566);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a.elementor-item-active {
	color: var(--e-global-color-primary);
	background-color: var(--e-global-color-ecb3566);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown .elementor-item,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown .elementor-sub-item {
	font-family: "Fira Sans", Sans;
	font-size: 15px;
	font-weight: 400;
	text-decoration: none;
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--dropdown a {
	padding-left: 30px;
	padding-right: 30px;
}

.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu--main>.elementor-nav-menu>li>.elementor-nav-menu--dropdown,
.elementor-37130 .elementor-element.elementor-element-43c0836 .elementor-nav-menu__container.elementor-nav-menu--dropdown {
	margin-top: 20px !important;
}

.elementor-37130 .elementor-element.elementor-element-43c0836 div.elementor-menu-toggle {
	color: var(--e-global-color-accent);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 div.elementor-menu-toggle svg {
	fill: var(--e-global-color-accent);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 div.elementor-menu-toggle:hover {
	color: var(--e-global-color-29f88a8);
}

.elementor-37130 .elementor-element.elementor-element-43c0836 div.elementor-menu-toggle:hover svg {
	fill: var(--e-global-color-29f88a8);
}

@media(max-width:1024px) {
	.elementor-37130 .elementor-element.elementor-element-58edd96 {
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}

	.elementor-37130 .elementor-element.elementor-element-ebb3272 {
		text-align: right;
	}

	.elementor-37130 .elementor-element.elementor-element-fe63449 {
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.elementor-37130 .elementor-element.elementor-element-43c0836 {
		--nav-menu-icon-size: 28px;
	}

	.elementor-37130 .elementor-element.elementor-element-ea0ce7b {
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
	}
}

@media(min-width:768px) {
	.elementor-37130 .elementor-element.elementor-element-58edd96 {
		width: 30%;
	}

	.elementor-37130 .elementor-element.elementor-element-fe63449 {
		width: 100%;
	}

	.elementor-37130 .elementor-element.elementor-element-ea0ce7b {
		width: 21.332%;
	}
}

@media(max-width:1024px) and (min-width:768px) {
	
	.elementor-37130 .elementor-element.elementor-element-58edd96 {
		width: 48%;
	}

	.elementor-37130 .elementor-element.elementor-element-fe63449 {
		width: 10%;
	}

	.elementor-37130 .elementor-element.elementor-element-ea0ce7b {
		width: 40%;
	}
}

@media(max-width:767px) {
	.elementor-37130 .elementor-element.elementor-element-ebb3272 {
		text-align: center;
	}
	.elementor-37130 .elementor-element.elementor-element-58edd96 {
		width: 55%;
		-webkit-order: 2;
		-ms-flex-order: 2;
		order: 2;
	}

	.elementor-37130 .elementor-element.elementor-element-fe63449 {
		width: 25%;
	}

	.elementor-37130 .elementor-element.elementor-element-ea0ce7b {
		-webkit-order: 3;
		-ms-flex-order: 3;
		order: 3;
	}
}